import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Subscription, map, tap } from 'rxjs';
import { Surcharge, SurchargeType } from '../../api/types/GraphQL';
import { SettingsService } from '../../services/settings.service';
import * as globals from '../../globals';
import { GqlErrorService } from '../../services/gql-error.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AppComponentLocation, SurchargeTaxCodes } from '../../api/types/enums';
import { SurchargeResponse } from '../../api/types/types';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-surcharges',
  templateUrl: './surcharges.component.html',
  styleUrls: ['./surcharges.component.scss'],
})
export class SurchargesComponent implements OnInit, AfterViewInit {
  location: string;

  AppComponentLocation = AppComponentLocation;

  surchargesQuery: QueryRef<SurchargeResponse>;
  surchargesQuerySub: Subscription;

  dataSource: MatTableDataSource<Surcharge>;

  numberOfItems: number = 0;
  itemsPerPage: number = globals.globalOffset;
  page: number = 1;

  selection = new SelectionModel<Surcharge>(true, []);

  SurchargeType = SurchargeType;
  SurchargeTaxCodes = SurchargeTaxCodes;
  regionNames = new Intl.DisplayNames([globals.appLanguage], {
    type: 'region',
  });
  displayedColumns = ['select', 'code', 'name', 'amountFee', 'amountPercentage', 'tax', 'country'];

  surchargeIds: string[] = [];

  disableSelectAll: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private settingsService: SettingsService,
    private errorService: GqlErrorService,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: string[],
  ) {}

  ngOnInit(): void {
    this.location = this.router.url.split(/[\/?]+/)[3];

    this.surchargesQuery = this.settingsService.getProductSurchargeseQuery({
      page: this.page,
      offset: this.itemsPerPage,
    });

    this.surchargesQuerySub = this.surchargesQuery.valueChanges
      .pipe(
        tap((data) => (this.numberOfItems = data.data.surcharges.itemsFound)),
        map((data) => data.data.surcharges.items),
      )
      .subscribe({
        next: (surcharges) => {
          surcharges.forEach((surcharge) => this.surchargeIds.push(surcharge.id));
          this.dataSource = new MatTableDataSource(surcharges);

          if (this.location === AppComponentLocation.product && this.areEqual(this.surchargeIds, this.dialogData))
            this.disableSelectAll = true;
        },
        error: (error) => {
          this.errorService.getGqlError(error);
        },
      });
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe((page: PageEvent) => {
        if (this.itemsPerPage !== page.pageSize) {
          this.itemsPerPage = page.pageSize;
        }
        this.page = page.pageIndex + 1;

        let variables = {
          input: {
            page: this.page,
            offset: this.itemsPerPage,
          },
        };
        this.surchargesQuery.refetch(variables);
      });

      this.paginator.pageIndex = this.page - 1;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter((row1) => !this.dialogData.some((row2) => row2 === row1.id)).length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    const selectableRows = this.dataSource.data.filter((row) => !this.dialogData.includes(row.id));
    this.selection.select(...selectableRows);
  }

  areEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every((element) => {
        if (array2.includes(element)) {
          return true;
        }

        return false;
      });
    }

    return false;
  }
}
