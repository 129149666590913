import { gql } from 'apollo-angular';
import { TenderFragments } from '../fragments/tender.fragments';

export const TenderStartMutation = gql`
  mutation TenderStart($input: TenderStart!) {
    tenderStart(input: $input) {
      actionCode
      couponCode
      creditPoints
      date
      debtorId
      deliveryAddress {
        ...TenderAddress
      }
      isEditable
      email
      externalId
      extra3
      extra4
      invoiceAddress {
        ...TenderAddress
      }
      invoiceUserId
      itemCount
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      orderId
      incentivesApplied
      carriers {
        ...TenderCarrier
      }
      payMethods {
        ...TenderPaymethod
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      reference
      remarks
      shopId
      siteId
      source
      status
      tenderId
      total {
        ...TenderTotal
      }
      type
      userId
      valuePoints
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
  ${TenderFragments.paymethods}
`;

export const TenderUpdateAddress = gql`
  mutation TenderUpdateAddress($input: TenderUpdateAddressInput!) {
    tenderUpdateAddress(input: $input) {
      deliveryAddress {
        ...TenderAddress
      }
      invoiceAddress {
        ...TenderAddress
      }
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
      status
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
  ${TenderFragments.total}
`;

export const TenderAddItems = gql`
  mutation TenderAddItems($input: TenderAddItemsInput!) {
    tenderAddItems(input: $input) {
      tender {
        items {
          childItems {
            ...TenderChildItems
          }
          ...TenderMainItem
        }
        carriers {
          ...TenderCarrier
        }
        paymentData {
          ...TenderPayment
        }
        postageData {
          ...TenderPostage
        }
        total {
          ...TenderTotal
        }
      }
      response {
        data
        messages
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderDeleteItem = gql`
  mutation TenderDeleteItem($input: TenderDeleteItemInput!) {
    tenderDeleteItem(input: $input) {
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdateItem = gql`
  mutation TenderUpdateItem($input: TenderUpdateItemInput!) {
    tenderUpdateItem(input: $input) {
      incentivesApplied
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdateDiscount = gql`
  mutation TenderUpdateDiscount($input: TenderDiscountInput!) {
    tenderUpdateDiscount(input: $input) {
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdatePayment = gql`
  mutation TenderUpdatePayment($input: TenderPaymentInput!) {
    tenderUpdatePayment(input: $input) {
      paymentData {
        ...TenderPayment
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
`;

export const TenderUpdate = gql`
  mutation TenderUpdate($input: TenderUpdateInput!) {
    tenderUpdate(input: $input) {
      reference
      remarks
      status
      isEditable
      deliveryAddress {
        ...TenderAddress
      }
      invoiceAddress {
        ...TenderAddress
      }
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      status
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdatePostage = gql`
  mutation TenderUpdatePostage($input: TenderPostageInput!) {
    tenderUpdatePostage(input: $input) {
      postageData {
        ...TenderPostage
      }
      paymentData {
        ...TenderPayment
      }
      total {
        ...TenderTotal
      }
      carriers {
        ...TenderCarrier
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.carriers}
`;

export const TenderProcess = gql`
  mutation TenderProcess($tenderId: String!) {
    tenderProcess(tenderId: $tenderId) {
      orderId
    }
  }
`;

export const TenderApplyIncentives = gql`
  mutation TenderApplyIncentives($tenderId: String!) {
    tenderApplyIncentives(tenderId: $tenderId) {
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      incentivesApplied
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderDelete = gql`
  mutation TenderDelete($tenderId: String!) {
    tenderDelete(tenderId: $tenderId)
  }
`;
