import { gql } from 'apollo-angular';

export const FavoriteListsQuery = gql`
  query favoriteLists($input: FavoriteListsSearchInput!) {
    favoriteLists(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        name
        companyId
        contactId
        customerId
        isDefault
        slug
        updatedAt
        createdAt
        products {
          itemsFound
        }
      }
    }
  }
`;

export const FavoriteListDetailsQuery = gql`
  query favoriteList($id: String!) {
    favoriteList(id: $id) {
      name
      companyId
      contactId
      customerId
      isDefault
      slug
      products {
        items {
          ... on Product {
            id
            language
            class
            isHidden
            name {
              value
            }
            productId
            class
            hasBundle
            isBundleLeader
            supplier
            manufacturerCode
            sku
            defaultLanguage
          }
        }
        itemsFound
      }
      clusters {
        items {
          id
          language
          class
          isHidden
          name {
            value
          }
          sku
          defaultLanguage
          categoryId
        }
        itemsFound
      }
      updatedAt
      createdAt
    }
  }
`;
