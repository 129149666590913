import { gql } from 'apollo-angular';

export const OrderSetStatus = gql`
  mutation OrderSetStatus($input: OrderSetStatusInput!) {
    orderSetStatus(input: $input) {
      id
      status
    }
  }
`;

export const OrderSendConfirmationEmailMutation = gql`
  mutation orderSendConfirmationEmail($orderId: Int!, $attachments: [Base64FileInput!]) {
    orderSendConfirmationEmail(orderId: $orderId, attachments: $attachments) {
      messageId
      success
    }
  }
`;
