import { Action } from '@ngrx/store';
import { AttributeFilter, Product } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Catalog] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Catalog] Get Current Page Size';
export const GET_CATALOG_COUNT = '[Catalog] Get Catalog Count';
export const LOAD_CATALOG = '[Catalog] Load Catalog';
export const LOAD_REFETCH = '[Catalog] REFETCH Catalog';
export const REFETCH_CATALOG = '[Catalog] Refetch Catalog';
export const LOAD_CATALOG_SUCCESS = '[Catalog] Load Catalog Success';
export const LOAD_CATALOG_FAILURE = '[Catalog] Load Catalog Failure';

export const ADD_PRODUCT_TO_LIST_CATALOG = '[Catalog] Add Product to List from Catalog';
export const DELETE_PRODUCT_FROM_LIST_CATALOG = '[Catalog] Delete Product from List  from Catalog';
export const CLEAR_PRODUCT_LIST_CATALOG = '[Catalog] Clear ProductList from Catalog';

export const ADD_PRODUCT_TO_LIST_QUOTES = '[Catalog] Add Product to List from Quotes';
export const DELETE_PRODUCT_FROM_LIST_QUOTES = '[Catalog] Delete Product from List  from Quotes';
export const CLEAR_PRODUCT_LIST_QUOTES = '[Catalog] Clear ProductList from Quots';

export const ADD_PRODUCT_TO_LIST_ORDERS = '[Catalog] Add Product to List from Orders';
export const DELETE_PRODUCT_FROM_LIST_ORDERS = '[Catalog] Delete Product from List  from Orders';
export const CLEAR_PRODUCT_LIST_ORDERS = '[Catalog] Clear ProductList from Orders';

export const GET_COLUMN_FILTERS = '[Catalog] Get Column Filters';
export const GET_MULTISELECT_FILTERS = '[Catalog] Get Multiselect Filters';
export const GET_GENERAL_FILTER = '[Catalog] Get General Filter';
export const GET_ATTRIBUTE_FILTRS = '[Catalog] Get Attribute Filters';
export const CLEAR_ALL_FILTERS = '[Catalog] Clear All Filters';

export const SAVE_QUERY_PARAMS = '[Catalog] Save Query Params';

export const SET_IMPERSONATE_USER = '[Catalog] Set Impersonate User';

export const CATALOG_PAGE_INITIALIZED = '[Catalog Page] Initialized';
export const CATALOG_PAGE_DESTROYED = '[Catalog Page] Destroyed';

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}
export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(
    public payload: {
      manufacturer: string[];
      supplier: string[];
    },
  ) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class GetAttributeFilters implements Action {
  readonly type = GET_ATTRIBUTE_FILTRS;

  constructor(public payload: AttributeFilter[]) {}
}

export class ClearAllFilters implements Action {
  readonly type = CLEAR_ALL_FILTERS;
}

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetCatalogCount implements Action {
  readonly type = GET_CATALOG_COUNT;

  constructor(public payload: number) {}
}

export class LoadCatalog implements Action {
  readonly type = LOAD_CATALOG;

  constructor(public payload: {}) {}
}

export class RefetchCatalog implements Action {
  readonly type = REFETCH_CATALOG;

  constructor(public payload: {}) {}
}

export class LoadCatalogSuccess implements Action {
  readonly type = LOAD_CATALOG_SUCCESS;

  constructor(public payload: Product[]) {}
}

export class LoadCatalogFailure implements Action {
  readonly type = LOAD_CATALOG_FAILURE;

  constructor(public payload: Error) {}
}

export class AddProductToListCatalog implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_CATALOG;

  constructor(public payload: Product) {}
}

export class DeleteProductFromListCatalog implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_CATALOG;

  constructor(public payload: number) {}
}

export class ClearProductListCatalog implements Action {
  readonly type = CLEAR_PRODUCT_LIST_CATALOG;
}

export class AddProductToListQuotes implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_QUOTES;

  constructor(public payload: Product) {}
}

export class DeleteProductFromListQuotes implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_QUOTES;

  constructor(public payload: number) {}
}

export class ClearProductListQuotes implements Action {
  readonly type = CLEAR_PRODUCT_LIST_QUOTES;
}

export class AddProductToListOrders implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_ORDERS;

  constructor(public payload: Product) {}
}

export class DeleteProductFromListOrders implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_ORDERS;

  constructor(public payload: number) {}
}

export class ClearProductListOrders implements Action {
  readonly type = CLEAR_PRODUCT_LIST_ORDERS;
}

export class SvaeQueryParams implements Action {
  readonly type = SAVE_QUERY_PARAMS;

  constructor(public payload: {}) {}
}

export class SetImpersonateUser implements Action {
  readonly type = SET_IMPERSONATE_USER;

  constructor(public payload: number) {}
}

export class CatalogPageInitialized implements Action {
  readonly type = CATALOG_PAGE_INITIALIZED;
}

export class CatalogPageDestroyed implements Action {
  readonly type = CATALOG_PAGE_DESTROYED;
}

export type CatalogActions =
  | GetColumnFilters
  | GetMultiselectFilters
  | GetGeneralFilter
  | GetAttributeFilters
  | ClearAllFilters
  | GetCurrentPage
  | GetCurrentPageSize
  | GetCatalogCount
  | LoadCatalog
  | RefetchCatalog
  | LoadCatalogSuccess
  | LoadCatalogFailure
  | AddProductToListCatalog
  | DeleteProductFromListCatalog
  | ClearProductListCatalog
  | AddProductToListQuotes
  | DeleteProductFromListQuotes
  | ClearProductListQuotes
  | AddProductToListOrders
  | DeleteProductFromListOrders
  | ClearProductListOrders
  | SvaeQueryParams
  | SetImpersonateUser
  | CatalogPageInitialized
  | CatalogPageDestroyed;
