import { Action } from '@ngrx/store';
import { Order } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Quotes] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Quotes] Get Current Page Size';
export const GET_QUOTES_COUNT = '[Quotes] Get Quotes Count';
export const LOAD_QUOTES = '[Quotes] Load Quotes';
export const REFETCH_QUOTES = '[Quotes] Refetch Quotes';
export const LOAD_QUOTES_SUCCESS = '[Quotes] Load Quotes Success';
export const LOAD_QUOTES_FAILURE = '[Quotes] Load Quotes Failure';

export const ADD_QUOTE = '[Quotes] Add Quote';
export const SAVE_QUOTE = '[Quotes] Save Quote';
export const UPDATE_QUOTE = '[Quotes] Update Quote';
export const DELETE_QUOTE = '[Quotes] Delete Quote';

export const ADD_QUOTE_TO_LIST = '[Quotes] Add Selected Quote to List';
export const DELETE_QUOTE_FROM_LIST = '[Quotes] Delete Quote from List';
export const CLEAR_QUOTES_LIST = '[Quotes] Clear Quotes List';

export const GET_COLUMN_FILTERS = '[Quotes] Get Column Filters';
export const GET_MULTISELECT_FILTERS = '[Quotes] Get Multiselect Filters';
export const GET_GENERAL_FILTER = '[Quotes] Get General Filter';
export const CLEAR_ALL_FILTERS = '[Quotes] Clear All Filters';

export const PRODUCT_TABLE_COLUMNS = '[Quotes] Product Table Columns';
export const ORDERS_TABLE_COLUMNS = '[Quotes] Orders Table Columns';

export const QUOTES_PAGE_INITIALIZED = '[Quotes Page] Initialized';
export const QUOTES_PAGE_DESTROYED = '[Quots Page] Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetQuotesCount implements Action {
  readonly type = GET_QUOTES_COUNT;

  constructor(public payload: number) {}
}

export class LoadQuotes implements Action {
  readonly type = LOAD_QUOTES;

  constructor(public payload: {}) {}
}

export class RefetchQuotes implements Action {
  readonly type = REFETCH_QUOTES;

  constructor(public payload: {}) {}
}

export class LoadQuotesSuccess implements Action {
  readonly type = LOAD_QUOTES_SUCCESS;

  constructor(public payload: Order[]) {}
}

export class LoadQuotesFailure implements Action {
  readonly type = LOAD_QUOTES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddQuote implements Action {
  readonly type = ADD_QUOTE;

  constructor(public payload: Order) {}
}

export class UpdateQuote implements Action {
  readonly type = UPDATE_QUOTE;

  constructor(public payload: { id: number; updatedQuote: Order }) {}
}

export class DeleteQuote implements Action {
  readonly type = DELETE_QUOTE;

  constructor(public payload: number) {}
}

export class AddQuoteToList implements Action {
  readonly type = ADD_QUOTE_TO_LIST;

  constructor(public payload: Order) {}
}

export class DeleteQuoteFromList implements Action {
  readonly type = DELETE_QUOTE_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearQuotesList implements Action {
  readonly type = CLEAR_QUOTES_LIST;
}

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}
export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(
    public payload: {
      manufacturer: string[];
      supplier: string[];
    },
  ) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class ClearAllFilters implements Action {
  readonly type = CLEAR_ALL_FILTERS;
}

export class ProductTableColumns implements Action {
  readonly type = PRODUCT_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class OrdersTableColumns implements Action {
  readonly type = ORDERS_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class QuotesPageInitialized implements Action {
  readonly type = QUOTES_PAGE_INITIALIZED;
}

export class QuotesPageDestroyed implements Action {
  readonly type = QUOTES_PAGE_DESTROYED;
}

export type QuotesActions =
  | GetCurrentPage
  | GetCurrentPageSize
  | GetQuotesCount
  | LoadQuotes
  | RefetchQuotes
  | LoadQuotesSuccess
  | LoadQuotesFailure
  | AddQuote
  | UpdateQuote
  | DeleteQuote
  | AddQuoteToList
  | DeleteQuoteFromList
  | ClearQuotesList
  | GetColumnFilters
  | GetMultiselectFilters
  | GetGeneralFilter
  | ClearAllFilters
  | ProductTableColumns
  | OrdersTableColumns
  | QuotesPageInitialized
  | QuotesPageDestroyed;
