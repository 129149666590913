import { Action } from '@ngrx/store';
import { Tenant } from './auth.reducer';

export const LOGIN_START = '[Auth] Login Start';
export const LOGIN = '[Auth] Login';
export const AUTO_LOGIN = '[Auth] Auto Login';
export const LOGIN_ERROR = '[Auth] Login Error';
export const LOGOUT_START = '[Auth] Logout Start';
export const LOGOUT = '[Auth] Logout';
export const SET_TENANTS = '[Auth] Set tenants';
export const SET_ACTIVE_TENANT = '[Auth] Set active tenant';
export const SET_USER_ID = '[Auth] Set user id';

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: {}) {}
}

export class AutoLogin implements Action {
  readonly type = AUTO_LOGIN;

  constructor(public payload: {}) {}
}

export class SetTenants implements Action {
  readonly type = SET_TENANTS;

  constructor(public payload: Tenant[]) {}
}

export class SetActiveTenant implements Action {
  readonly type = SET_ACTIVE_TENANT;

  constructor(public payload: string) {}
}

export class SetUserId implements Action {
  readonly type = SET_USER_ID;

  constructor(public payload: number) {}
}

export class LoginStart implements Action {
  readonly type = LOGIN_START;

  constructor(
    public payload: {
      email: string;
      password: string;
      persistentLogin: boolean;
    },
  ) {}
}

export class LoginError implements Action {
  readonly type = LOGIN_ERROR;

  constructor(public payload: Error) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutStart implements Action {
  readonly type = LOGOUT_START;
}

export type AuthActions =
  | Login
  | AutoLogin
  | Logout
  | SetTenants
  | SetActiveTenant
  | SetUserId
  | LoginStart
  | LoginError
  | LogoutStart;
