import { gql } from 'apollo-angular';

export const UpdateFavoriteListMutation = gql`
  mutation UpdateFavoriteList($id: String!, $input: FavoriteListsUpdateInput!) {
    favoriteListUpdate(id: $id, input: $input) {
      id
      name
      slug
      isDefault
    }
  }
`;

export const AddProductsToFavoriteListMutation = gql`
  mutation AddProductsToFavoriteList($id: String!, $input: FavoriteListsItemsInput!) {
    favoriteListAddItems(id: $id, input: $input) {
      id
      products {
        items {
          ... on Product {
            id
            language
            class
            isHidden
            name {
              value
            }
            class
            hasBundle
            productId
            isBundleLeader
            supplier
            manufacturerCode
            sku
            defaultLanguage
          }
        }
        itemsFound
      }
    }
  }
`;

export const CreateFavoriteListMutation = gql`
  mutation CreateFavoriteList($input: FavoriteListsCreateInput!) {
    favoriteListCreate(input: $input) {
      id
      name
    }
  }
`;

export const RemoveProductsFromFavoriteListMutation = gql`
  mutation RemoveProductsFromFavoriteList($id: String!, $input: FavoriteListsItemsInput!) {
    favoriteListRemoveItems(id: $id, input: $input) {
      id
      products {
        items {
          ... on Product {
            id
            language
            class
            isHidden
            name {
              value
            }
            productId
            class
            hasBundle
            isBundleLeader
            supplier
            manufacturerCode
            sku
            defaultLanguage
          }
        }
        itemsFound
      }
    }
  }
`;

export const DeleteFavoriteListMutation = gql`
  mutation DeleteFavoriteList($id: String!) {
    favoriteListDelete(id: $id)
  }
`;
