import { BusinessRule } from 'src/app/shared/api/types/GraphQL';
import * as BusinessRulesActions from './business-rules.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  businessRules: BusinessRule[];
  currentPage: number;
  currentPageSize: number;
  businessRulesCount: number;
  error: Error;
  generalFilterValue: string;
  selectedBusinessRulesList: BusinessRule[];
  businessRulesTableColumns: {};
}

const initialState: State = {
  loading: false,
  loaded: false,
  businessRules: [],
  currentPage: 1,
  currentPageSize: 5,
  businessRulesCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedBusinessRulesList: [],
  businessRulesTableColumns: {
    displayedColumns: ['select', 'id', 'name', 'dateCreated', 'dateUpdated', 'action'],
    hiddenColumns: ['select', 'action'],
    disabledSuffixLength: 1,
    availableColumns: ['select', 'action'],
    disabledColumns: ['id', 'name'],
  },
};

export function businessRulesReducer(state: State = initialState, action: BusinessRulesActions.BusinessRulesActions) {
  switch (action.type) {
    case BusinessRulesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case BusinessRulesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case BusinessRulesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case BusinessRulesActions.GET_BUSINESS_RULES_COUNT:
      return {
        ...state,
        businessRulesCount: action.payload,
      };
    case BusinessRulesActions.LOAD_BUSINESS_RULES:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case BusinessRulesActions.REFETCH_BUSINESS_RULES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case BusinessRulesActions.LOAD_BUSINESS_RULES_SUCCESS:
      return {
        ...state,
        businessRules: [...action.payload],
        loading: false,
        loaded: true,
      };
    case BusinessRulesActions.LOAD_BUSINESS_RULES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case BusinessRulesActions.ADD_BUSINESS_RULE:
      return {
        ...state,
        businessRules: [...state.businessRules, action.payload],
      };
    case BusinessRulesActions.ADD_BUSINESS_RULE_TO_LIST:
      if (state.selectedBusinessRulesList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedBusinessRulesList: [...state.selectedBusinessRulesList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case BusinessRulesActions.DELETE_BUSINESS_RULE_FROM_LIST:
      if (state.selectedBusinessRulesList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedBusinessRulesList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedBusinessRulesList: state.selectedBusinessRulesList.filter((item, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case BusinessRulesActions.CLEAR_BUSINESS_RULES_LIST:
      return {
        ...state,
        selectedBusinessRulesList: [],
      };
    case BusinessRulesActions.BUSINESS_RULES_TABLE_COLUMNS:
      return {
        ...state,
        businessRulesTableColumns: action.payload,
      };

    default:
      return state;
  }
}
