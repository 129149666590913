import { gql } from 'apollo-angular';
import * as globals from '../../globals';

export const ContactQuery = gql`
  query contact($contactId: Float, $id: Float) {
    contact(contactId: $contactId, id: $id) {
      email
      firstName
      lastName
      phone
      contactId
      id
      gender
      company {
        name
      }
    }
  }
`;
export const ContactsDropdownQuery = gql`
  query contacts($input: ContactSearchArguments!) {
    contacts(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ContactsListQuery = gql`
  query contacts($input: ContactSearchArguments!) {
    contacts(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        contactId
        firstName
        lastName
        email
        phone
        company {
          name
        }
      }
    }
  }
`;

export const ContactDetailsQuery = gql`
  query contact($contactId: Float, $id: Float) {
    contact(contactId: $contactId, id: $id) {
      id
      contactId
      firstName
      lastName
      middleName
      email
      gender
      dateOfBirth
      primaryLanguage
      expires
      isLoggedIn
      mailingList
      notes
      dateCreated
      lastModifiedDate
      iban
      bankAccount
      bic
      parentCompanyId
      company {
        name
      }
    }
  }
`;

export const ContactDetailsAttributesQuery = gql`
query contactDetailsAttributes ($contactId: Float,$attrPage: Int!, $attrOffset: Int!, $attrName: [String!], $attrType: [AttributeType!], $attrIsPublic: Boolean, $attrIsSearchable: Boolean) {
  contact(contactId: $contactId) {   
   attributeValues(filter: {page: $attrPage, offset: $attrOffset, name: $attrName, type: $attrType, isPublic: $attrIsPublic, isSearchable: $attrIsSearchable}) {
    itemsFound
    page
    items {
      attributeId
      textValue(language: "${globals.appLanguage}") {
        values
      }
      enumValue
      intValue
      decimalValue
      dateValue
      attributeDescription {
        id
        searchId
        name
        description(language: "${globals.appLanguage}") {
          value
        }
        type
        typeParam
        group
        isSearchable
        isPublic
      }
    }
   }
  }
}
`;
