import { Carrier } from 'src/app/shared/api/types/GraphQL';
import * as CarriersActions from './carriers.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  carriers: Carrier[];
  currentPage: number;
  currentPageSize: number;
  carriersCount: number;
  error: Error;
  generalFilterValue: string;
  activeTab: number;
  selectedCarriersList: Carrier[];
  carriersTableColumns: {};
}

const initialState: State = {
  loading: false,
  loaded: false,
  carriers: [],
  currentPage: 1,
  currentPageSize: 5,
  carriersCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedCarriersList: [],
  carriersTableColumns: {
    displayedColumns: ['select', 'logo', 'name', 'trackingUrl', 'costs', 'dateCreated', 'dateUpdated', 'action'],
    hiddenColumns: ['select', 'action'],
    disabledSuffixLength: 1,
    availableColumns: ['select', 'action'],
    disabledColumns: ['name'],
  },
};

export function carriersReducer(state: State = initialState, action: CarriersActions.CarriersActions) {
  switch (action.type) {
    case CarriersActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case CarriersActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case CarriersActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case CarriersActions.GET_CARRIERS_COUNT:
      return {
        ...state,
        carriersCount: action.payload,
      };
    case CarriersActions.GET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case CarriersActions.LOAD_CARRIERS:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case CarriersActions.REFETCH_CARRIERS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case CarriersActions.LOAD_CARRIERS_SUCCESS:
      return {
        ...state,
        carriers: [...action.payload],
        loading: false,
        loaded: true,
      };
    case CarriersActions.LOAD_CARRIERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CarriersActions.ADD_CARRIER:
      return {
        ...state,
        carriers: [...state.carriers, action.payload],
      };
    case CarriersActions.ADD_CARRIER_TO_LIST:
      if (state.selectedCarriersList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedCarriersList: [...state.selectedCarriersList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CarriersActions.DELETE_CARRIER_FROM_LIST:
      if (state.selectedCarriersList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedCarriersList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedCarriersList: state.selectedCarriersList.filter((item, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case CarriersActions.CLEAR_CARRIERS_LIST:
      return {
        ...state,
        selectedCarriersList: [],
      };
    case CarriersActions.CARRIERS_TABLE_COLUMNS:
      return {
        ...state,
        carriersTableColumns: action.payload,
      };

    default:
      return state;
  }
}
