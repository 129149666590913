import { gql } from 'apollo-angular';

export const TaxesQuery = gql`
  query taxes($input: TaxSearchInput) {
    taxes(input: $input) {
      items {
        id
        code
        zone
        ratio
        shopId
        exportCode
      }
      itemsFound
    }
  }
`;

export const TaxDetailsQuery = gql`
  query tax($id: ID!) {
    tax(id: $id) {
      id
      code
      zone
      ratio
      shopId
      exportCode
    }
  }
`;
