import { Action } from '@ngrx/store';
import { AttributeFilter, Cluster, Product } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Products] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Products] Get Current Page Size';
export const GET_PRODUCTS_COUNT = '[Products] Get Products Count';
export const LOAD_PRODUCTS = '[Products] Load Products';
export const REFETCH_PRODUCTS = '[Products] Refetch Products';
export const LOAD_PRODUCTS_SUCCESS = '[Products] Load Products Success';
export const LOAD_PRODUCTS_FAILURE = '[Products] Load Products Failure';

export const ADD_PRODUCT_TO_LIST_PRODUCTS = '[Products] Add Product to List from Products';
export const DELETE_PRODUCT_FROM_LIST_PRODUCTS = '[Products] Delete Product from List  from Products';
export const CLEAR_PRODUCT_LIST_PRODUCTS = '[Products] Clear ProductList from Products';

export const ADD_PRODUCT_TO_LIST_QUOTES = '[Products] Add Product to List from Quotes';
export const ADD_CLUSTER_TO_LIST_QUOTES = '[Products] Add Cluster to List from Quotes';
export const ADD_CONFIGURABLE_CLUSTER_TO_LIST_QUOTES = '[Products] Add Configurable Cluster to List from Quotes';
export const DELETE_PRODUCT_FROM_LIST_QUOTES = '[Products] Delete Product from List  from Quotes';
export const DELETE_PRODUCT_FROM_CLUSTER_QUOTES = '[Products] Delete Product from Cluster from Quotes';
export const DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER_QUOTES =
  '[Products] Delete Product from Configurable Cluster from Quotes';
export const CLEAR_PRODUCT_LIST_QUOTES = '[Products] Clear ProductList from Quots';

export const ADD_PRODUCT_TO_LIST_ORDERS = '[Products] Add Product to List from Orders';
export const ADD_CLUSTER_TO_LIST_ORDERS = '[Products] Add Cluster to List from Orders';
export const ADD_CONFIGURABLE_CLUSTER_TO_LIST_ORDERS = '[Products] Add Configurable Cluster to List from Orders';
export const DELETE_PRODUCT_FROM_LIST_ORDERS = '[Products] Delete Product from List  from Orders';
export const DELETE_PRODUCT_FROM_CLUSTER_ORDERS = '[Products] Delete Product from Cluster from Orders';
export const DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER_ORDERS =
  '[Products] Delete Product from Configurable Cluster from Orders';
export const CLEAR_PRODUCT_LIST_ORDERS = '[Products] Clear ProductList from Orders';

export const ADD_PRODUCT_TO_LIST_FAVORITES = '[Products] Add Product to List from Favorites';
export const DELETE_PRODUCT_FROM_LIST_FAVORITES = '[Products] Delete Product from List from Favorites';
export const CLEAR_PRODUCT_LIST_FAVORITES = '[Products] Clear ProductList from Favorites';

export const GET_COLUMN_FILTERS = '[Products] Get Column Filters';
export const GET_MULTISELECT_FILTERS = '[Products] Get Multiselect Filters';
export const GET_GENERAL_FILTER = '[Products] Get General Filter';
export const GET_ATTRIBUTE_FILTRS = '[Products] Get Attribute Filters';
export const CLEAR_ALL_FILTERS = '[Products] Clear All Filters';

export const PRODUCT_TABLE_COLUMNS = '[Products] Product Table Columns';

export const PRODUCTS_PAGE_INITIALIZED = '[Products Page] Initialized';
export const PRODUCTS_PAGE_DESTROYED = '[Products Page] Destroyed';

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}
export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(
    public payload: {
      manufacturer: string[];
      supplier: string[];
    },
  ) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class GetAttributeFilters implements Action {
  readonly type = GET_ATTRIBUTE_FILTRS;

  constructor(public payload: AttributeFilter[]) {}
}

export class ClearAllFilters implements Action {
  readonly type = CLEAR_ALL_FILTERS;
}

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetProductsCount implements Action {
  readonly type = GET_PRODUCTS_COUNT;

  constructor(public payload: number) {}
}

export class LoadProducts implements Action {
  readonly type = LOAD_PRODUCTS;

  constructor(public payload: {}) {}
}

export class RefetchProducts implements Action {
  readonly type = REFETCH_PRODUCTS;

  constructor(public payload: {}) {}
}

export class LoadProductsSuccess implements Action {
  readonly type = LOAD_PRODUCTS_SUCCESS;

  constructor(public payload: Product[]) {}
}

export class LoadProductsFailure implements Action {
  readonly type = LOAD_PRODUCTS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddProductToListProducts implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_PRODUCTS;

  constructor(public payload: Product) {}
}

export class DeleteProductFromListProducts implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_PRODUCTS;

  constructor(public payload: number) {}
}

export class ClearProductListProducts implements Action {
  readonly type = CLEAR_PRODUCT_LIST_PRODUCTS;
}

export class AddProductToListQuotes implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_QUOTES;

  constructor(public payload: Product) {}
}

export class AddClusterToListQuotes implements Action {
  readonly type = ADD_CLUSTER_TO_LIST_QUOTES;

  constructor(public payload: Cluster) {}
}

export class AddConfigurableClusterToListQuotes implements Action {
  readonly type = ADD_CONFIGURABLE_CLUSTER_TO_LIST_QUOTES;

  constructor(public payload: Cluster) {}
}

export class DeleteProductFromListQuotes implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_QUOTES;

  constructor(public payload: number) {}
}

export class DeleteProductFromClusterQuotes implements Action {
  readonly type = DELETE_PRODUCT_FROM_CLUSTER_QUOTES;

  constructor(public payload: { clusterId: number; productId: number }) {}
}

export class DeleteProductFromConfigurableClusterQuotes implements Action {
  readonly type = DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER_QUOTES;

  constructor(public payload: { clusterId: number; productId: number }) {}
}

export class ClearProductListQuotes implements Action {
  readonly type = CLEAR_PRODUCT_LIST_QUOTES;
}

export class AddProductToListOrders implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_ORDERS;

  constructor(public payload: Product) {}
}

export class AddClusterToListOrders implements Action {
  readonly type = ADD_CLUSTER_TO_LIST_ORDERS;

  constructor(public payload: Cluster) {}
}

export class AddConfigurableClusterToListOrders implements Action {
  readonly type = ADD_CONFIGURABLE_CLUSTER_TO_LIST_ORDERS;

  constructor(public payload: Cluster) {}
}

export class DeleteProductFromListOrders implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_ORDERS;

  constructor(public payload: number) {}
}

export class DeleteProductFromClusterOrders implements Action {
  readonly type = DELETE_PRODUCT_FROM_CLUSTER_ORDERS;

  constructor(public payload: { clusterId: number; productId: number }) {}
}

export class DeleteProductFromConfigurableClusterOrders implements Action {
  readonly type = DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER_ORDERS;

  constructor(public payload: { clusterId: number; productId: number }) {}
}

export class ClearProductListOrders implements Action {
  readonly type = CLEAR_PRODUCT_LIST_ORDERS;
}

export class AddProductToListFavorites implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_FAVORITES;

  constructor(public payload: Product) {}
}

export class DeleteProductFromListFavorites implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_FAVORITES;

  constructor(public payload: number) {}
}

export class ClearProductListFavorites implements Action {
  readonly type = CLEAR_PRODUCT_LIST_FAVORITES;
}

export class ProductTableColumns implements Action {
  readonly type = PRODUCT_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}
export class ProductsPageInitialized implements Action {
  readonly type = PRODUCTS_PAGE_INITIALIZED;
}

export class ProductsPageDestroyed implements Action {
  readonly type = PRODUCTS_PAGE_DESTROYED;
}

export type ProductsActions =
  | GetColumnFilters
  | GetMultiselectFilters
  | GetGeneralFilter
  | GetAttributeFilters
  | ClearAllFilters
  | GetCurrentPage
  | GetCurrentPageSize
  | GetProductsCount
  | LoadProducts
  | RefetchProducts
  | LoadProductsSuccess
  | LoadProductsFailure
  | AddProductToListProducts
  | DeleteProductFromListProducts
  | ClearProductListProducts
  | AddProductToListQuotes
  | AddClusterToListQuotes
  | AddConfigurableClusterToListQuotes
  | DeleteProductFromListQuotes
  | DeleteProductFromClusterQuotes
  | DeleteProductFromConfigurableClusterQuotes
  | ClearProductListQuotes
  | AddProductToListOrders
  | AddClusterToListOrders
  | AddConfigurableClusterToListOrders
  | DeleteProductFromListOrders
  | DeleteProductFromClusterOrders
  | DeleteProductFromConfigurableClusterOrders
  | ClearProductListOrders
  | AddProductToListFavorites
  | DeleteProductFromListFavorites
  | ClearProductListFavorites
  | ProductTableColumns
  | ProductsPageInitialized
  | ProductsPageDestroyed;
