<div class="page-not-found-wrapper">
  <div class="page-not-found-header">
    404
    <img src="../../assets/images/404.svg" alt="Page not found" />
  </div>
  <div class="page-not-found-message">We can't find the page you are looking for :(</div>
  <div class="links-wrapper">
    <div class="desc">Here are some helpful links instead:</div>
    <div class="link-btns">
      <button mat-button color="primary" routerLink="/companies">Home</button>
      <button mat-button color="primary">Help</button>
      <button mat-button color="primary">FAQ</button>
    </div>
  </div>
</div>
