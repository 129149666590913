import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, Observable, of, Subscription, takeUntil, tap } from 'rxjs';

import * as ProductsActions from './products.actions';
import * as fromApp from '../../store/app.reducer';
import { ProductsService } from '../products.service';
import { NavigationStart, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { ProductListResponse } from 'src/app/shared/api/types/types';
import { Product } from 'src/app/shared/api/types/GraphQL';

@Injectable()
export class ProductsEffects {
  productsQuery: QueryRef<ProductListResponse>;
  productsQuerySub: Observable<any>;
  browserRefresh: boolean;
  refreshSub: Subscription;
  queryRefetch: boolean = false;
  location: string = '';

  constructor(
    private actions: Actions,
    private productsService: ProductsService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.refreshSub = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
    });
  }

  public readonly loadProducts: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(ProductsActions.LOAD_PRODUCTS),
      mergeMap((action: ProductsActions.LoadProducts) => {
        this.productsQuery = this.productsService.getAllProducts(action.payload);

        this.productsQuerySub = this.productsQuery.valueChanges.pipe(
          map((data) => {
            this.productsService.imgLoading = true;
            if (!this.queryRefetch) {
              this.store.dispatch(new ProductsActions.GetCurrentPage(1));
              this.store.dispatch(new ProductsActions.GetCurrentPageSize(5));
              this.store.dispatch(new ProductsActions.ClearAllFilters());
              this.store.dispatch(new ProductsActions.LoadProductsFailure(undefined));
              this.store.dispatch(new ProductsActions.ClearProductListOrders());
              this.store.dispatch(new ProductsActions.ClearProductListQuotes());
            }
            this.queryRefetch = true;
            this.store.dispatch(new ProductsActions.GetProductsCount(data.data.products.itemsFound));

            return new ProductsActions.LoadProductsSuccess(data.data.products.items as Product[]);
          }),
          catchError((error) => of(new ProductsActions.LoadProductsFailure(error))),
        );

        return this.productsQuerySub;
      }),
    );
  });

  public readonly refetchProducts = createEffect(
    () =>
      this.actions.pipe(
        ofType(ProductsActions.REFETCH_PRODUCTS),
        tap((action: ProductsActions.RefetchProducts) => {
          this.productsQuery.getLastResult();
          this.productsQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(ProductsActions.PRODUCTS_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(ProductsActions.PRODUCTS_PAGE_DESTROYED)))),
      ),
    );
  }
}
