import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { CompaniesDropdownQuery, CompanyListQuery, CompanyQuery } from '../shared/api/queries/company-queries';
import { ContactQuery, ContactsDropdownQuery } from '../shared/api/queries/contact-query';
import { CustomersDropdownQuery } from '../shared/api/queries/customer-queries';
import { CompanySearchArguments, CustomerSearchArguments } from '../shared/api/types/GraphQL';
import {
  CompanyListResponse,
  CompanyResponse,
  ContactListResponse,
  ContactResponse,
  CustomerListResponse,
} from '../shared/api/types/types';

@Injectable({ providedIn: 'root' })
export class CompaniesService {
  constructor(private apollo: Apollo) {}

  getCompanyList(variables: {}): QueryRef<CompanyListResponse> {
    return this.apollo.watchQuery<CompanyListResponse>({
      query: CompanyListQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getCompaniesDropdown(variables: CompanySearchArguments): QueryRef<CompanyListResponse> {
    return this.apollo.watchQuery<CompanyListResponse>({
      query: CompaniesDropdownQuery,
      variables: { input: variables },
    });
  }

  getCompany(variables: {}): QueryRef<CompanyResponse> {
    return this.apollo.watchQuery<CompanyResponse>({
      query: CompanyQuery,
      variables,
    });
  }
  getContact(variables: {}): QueryRef<ContactResponse> {
    return this.apollo.watchQuery<ContactResponse>({
      query: ContactQuery,
      variables,
    });
  }

  getContactsDropdown(variables: CompanySearchArguments): QueryRef<ContactListResponse> {
    return this.apollo.watchQuery<ContactListResponse>({
      query: ContactsDropdownQuery,
      variables: { input: variables },
    });
  }

  getCustomersDropdown(variables: CustomerSearchArguments): QueryRef<CustomerListResponse> {
    return this.apollo.watchQuery<CustomerListResponse>({
      query: CustomersDropdownQuery,
      variables: { input: variables },
    });
  }
}
