import { AttributeFilter, Product } from 'src/app/shared/api/types/GraphQL';
import * as ProductsActions from './products.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  products: Product[];
  currentPage: number;
  currentPageSize: number;
  productsCount: number;
  error: Error;
  columnFilterValues: {
    name: string;
    sku: string;
  };
  multiselectFilterValues: {
    manufacturer: string[];
    supplier: string[];
  };
  numberOfFiltersSelected: number;
  generalFilterValue: string;
  attributeFilters: AttributeFilter[];
  productListProducts: Product[];
  productListQuotes: any;
  productListOrders: any;
  productListFavorites: any;
  productListChangeMessage: string;
  productListProductsLength: number;
  productListQuotesLength: number;
  productListOrdersLength: number;
  productListFavoritesLength: number;
  productTableColumns: {
    displayedColumns: string[];
    hiddenColumns: string[];
    availableColumns: string[];
    disabledColumns: string[];
    disabledSuffixLength: number;
  };
}

const initialState: State = {
  loading: false,
  loaded: false,
  products: [],
  currentPage: 1,
  currentPageSize: 5,
  productsCount: 0,
  error: undefined,
  columnFilterValues: {
    name: '',
    sku: '',
  },
  multiselectFilterValues: {
    manufacturer: [],
    supplier: [],
  },
  numberOfFiltersSelected: 0,
  generalFilterValue: '',
  attributeFilters: [],
  productListProducts: [],
  productListQuotes: [],
  productListOrders: [],
  productListFavorites: [],
  productListChangeMessage: '',
  productListProductsLength: 0,
  productListQuotesLength: 0,
  productListOrdersLength: 0,
  productListFavoritesLength: 0,
  productTableColumns: {
    displayedColumns: [
      'select',
      'image',
      'sku',
      'name',
      'variants',
      'supplier',
      'price',
      'stock',
      'orderable',
      'status',
      'minQuantity',
      'qtySteps',
      'actionBtn',
    ],
    hiddenColumns: ['select', 'actionBtn'],
    availableColumns: ['select', 'actionBtn'],
    disabledColumns: ['sku', 'name'],
    disabledSuffixLength: 1,
  },
};

export function productsReducer(state: State = initialState, action: ProductsActions.ProductsActions) {
  switch (action.type) {
    case ProductsActions.GET_COLUMN_FILTERS:
      let numOfFilters = state.numberOfFiltersSelected;
      if (action.payload.filterValue === '' && state.columnFilterValues[action.payload.columnName] !== '') {
        numOfFilters -= 1;
      } else if (action.payload.filterValue !== '' && state.columnFilterValues[action.payload.columnName] === '') {
        numOfFilters += 1;
      }
      return {
        ...state,
        columnFilterValues: {
          ...state.columnFilterValues,
          [action.payload.columnName]: action.payload.filterValue,
        },
        numberOfFiltersSelected: numOfFilters,
      };
    case ProductsActions.GET_MULTISELECT_FILTERS:
      let stateLength =
        state.multiselectFilterValues.manufacturer.length + state.multiselectFilterValues.supplier.length;
      let payloadLength = action.payload.manufacturer.length + action.payload.supplier.length;
      let numOfMultyFilters = state.numberOfFiltersSelected;
      if (stateLength > payloadLength) {
        numOfMultyFilters = numOfMultyFilters - (stateLength - payloadLength);
      } else if (stateLength < payloadLength) {
        numOfMultyFilters = numOfMultyFilters + (payloadLength - stateLength);
      }
      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          manufacturer: action.payload.manufacturer,
          supplier: action.payload.supplier,
        },
        numberOfFiltersSelected: numOfMultyFilters,
      };
    case ProductsActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case ProductsActions.GET_ATTRIBUTE_FILTRS:
      return {
        ...state,
        attributeFilters: action.payload,
      };
    case ProductsActions.CLEAR_ALL_FILTERS:
      return {
        ...state,
        columnFilterValues: {
          name: '',
          sku: '',
        },
        multiselectFilterValues: {
          manufacturer: [],
          supplier: [],
        },
        generalFilterValue: '',
        numberOfFiltersSelected: 0,
      };
    case ProductsActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case ProductsActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case ProductsActions.GET_PRODUCTS_COUNT:
      return {
        ...state,
        productsCount: action.payload,
      };
    case ProductsActions.LOAD_PRODUCTS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case ProductsActions.REFETCH_PRODUCTS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case ProductsActions.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...action.payload],
        loading: false,
        loaded: true,
      };
    case ProductsActions.LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ProductsActions.ADD_PRODUCT_TO_LIST_PRODUCTS:
      if (state.productListProducts.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListProducts: [...state.productListProducts, action.payload],
          productListProductsLength: state.productListProductsLength + 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_LIST_PRODUCTS:
      if (state.productListProducts.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListProducts.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListProducts: state.productListProducts.filter((product, i) => {
            return i !== index;
          }),
          productListProductsLength: state.productListProductsLength - 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.CLEAR_PRODUCT_LIST_PRODUCTS:
      return {
        ...state,
        productListProducts: [],
        productListProductsLength: 0,
      };

    case ProductsActions.ADD_PRODUCT_TO_LIST_FAVORITES:
      if (state.productListFavorites.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListFavorites: [...state.productListFavorites, action.payload],
          productListFavoritesLength: state.productListFavoritesLength + 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_LIST_FAVORITES:
      if (state.productListFavorites.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListFavorites.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListFavorites: state.productListFavorites.filter((product, i) => {
            return i !== index;
          }),
          productListFavoritesLength: state.productListFavoritesLength - 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.CLEAR_PRODUCT_LIST_FAVORITES:
      return {
        ...state,
        productListFavorites: [],
        productListFavoritesLength: 0,
      };
    case ProductsActions.ADD_PRODUCT_TO_LIST_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListQuotes: [...state.productListQuotes, action.payload],
          productListChangeMessage: 'Product ' + action.payload.name[0]?.value + ' was added to the basket',
          productListQuotesLength: state.productListQuotesLength + 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.ADD_CLUSTER_TO_LIST_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload.id) === -1) {
        const numOfProducts = action.payload.products.length;
        return {
          ...state,
          productListQuotes: [...state.productListQuotes, action.payload],
          productListQuotesLength: state.productListQuotesLength + numOfProducts,
        };
      } else {
        const clusterIndex = state.productListQuotes.map((x) => x.id).indexOf(action.payload.id);
        const productsToAdd = [];
        for (let product of action.payload.products) {
          if (state.productListQuotes[clusterIndex].products.map((x) => x.id).indexOf(product.id) === -1) {
            productsToAdd.push(product);
          }
        }
        if (!!productsToAdd.length) {
          return {
            ...state,
            productListQuotes: [
              ...state.productListQuotes.slice(0, clusterIndex),
              {
                ...state.productListQuotes[clusterIndex],
                products: [...state.productListQuotes[clusterIndex].products, ...productsToAdd],
              },
              ...state.productListQuotes.slice(clusterIndex + 1),
            ],
            productListQuotesLength: state.productListQuotesLength + productsToAdd.length,
          };
        } else {
          return {
            ...state,
          };
        }
      }
    case ProductsActions.ADD_CONFIGURABLE_CLUSTER_TO_LIST_QUOTES:
      let isDiffrent = true;
      if (
        state.productListQuotes.map((x) => x.id).indexOf(action.payload.id) !== -1 &&
        state.productListQuotes[state.productListQuotes.map((x) => x.id).indexOf(action.payload.id)].products.length ===
          action.payload.products.length
      ) {
        const clusterIndex = state.productListQuotes.map((x) => x.id).indexOf(action.payload.id);
        for (let [i, product] of action.payload.products.entries()) {
          if (state.productListQuotes[clusterIndex].products.map((x) => x.id).indexOf(product.id) === -1) {
            isDiffrent = true;
            break;
          } else if (i === action.payload.products.length - 1) {
            isDiffrent = false;
          }
        }
      }
      if (isDiffrent) {
        const numOfProducts = action.payload.products.filter((product) => product.cluster !== null).length;
        return {
          ...state,
          productListQuotes: [...state.productListQuotes, action.payload],
          productListQuotesLength: state.productListQuotesLength + numOfProducts,
        };
      } else {
        return {
          ...state,
        };
      }

    case ProductsActions.DELETE_PRODUCT_FROM_LIST_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListQuotes.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListQuotes: state.productListQuotes.filter((product, i) => {
            return i !== index;
          }),
          productListChangeMessage:
            'Product ' + state.productListQuotes[index].name[0]?.value + ' was removed from the basket',
          productListQuotesLength: state.productListQuotesLength - 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_CLUSTER_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload.clusterId) !== -1) {
        const clusterIndex = state.productListQuotes.map((x) => x.id).indexOf(action.payload.clusterId);
        if (state.productListQuotes[clusterIndex].products.map((x) => x.id).indexOf(action.payload.productId) !== -1) {
          if (state.productListQuotes[clusterIndex].products.length > 1) {
            const productIndex = state.productListQuotes[clusterIndex].products
              .map((x) => x.id)
              .indexOf(action.payload.productId);
            return {
              ...state,
              productListQuotes: [
                ...state.productListQuotes.slice(0, clusterIndex),
                {
                  ...state.productListQuotes[clusterIndex],
                  products: [
                    ...state.productListQuotes[clusterIndex].products.filter((product, i) => {
                      return i !== productIndex;
                    }),
                  ],
                },
                ...state.productListQuotes.slice(clusterIndex + 1),
              ],
              productListQuotesLength: state.productListQuotesLength - 1,
            };
          } else {
            return {
              ...state,
              productListQuotes: state.productListQuotes.filter((product, i) => {
                return i !== clusterIndex;
              }),
              productListQuotesLength: state.productListQuotesLength - 1,
            };
          }
        } else {
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload.clusterId) !== -1) {
        const clusterIndex = state.productListQuotes.map((x) => x.id).indexOf(action.payload.clusterId);
        if (state.productListQuotes[clusterIndex].products.map((x) => x.id).indexOf(action.payload.productId) !== -1) {
          if (state.productListQuotes[clusterIndex].products.length > 1) {
            const productIndex = state.productListQuotes[clusterIndex].products
              .map((x) => x.id)
              .indexOf(action.payload.productId);
            return {
              ...state,
              productListQuotes: [
                ...state.productListQuotes.slice(0, clusterIndex),
                {
                  ...state.productListQuotes[clusterIndex],
                  products: [
                    ...state.productListQuotes[clusterIndex].products.filter((product, i) => {
                      return i !== productIndex;
                    }),
                  ],
                },
                ...state.productListQuotes.slice(clusterIndex + 1),
              ],
              productListQuotesLength: state.productListQuotesLength - 1,
            };
          } else {
            return {
              ...state,
              productListQuotes: state.productListQuotes.filter((product, i) => {
                return i !== clusterIndex;
              }),
              productListQuotesLength: state.productListQuotesLength - 1,
            };
          }
        } else {
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.CLEAR_PRODUCT_LIST_QUOTES:
      return {
        ...state,
        productListQuotes: [],
        productListQuotesLength: 0,
      };
    case ProductsActions.ADD_PRODUCT_TO_LIST_ORDERS:
      if (state.productListOrders.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListOrders: [...state.productListOrders, action.payload],
          productListChangeMessage: 'Product ' + action.payload.name[0]?.value + ' was added to the basket',
          productListOrdersLength: state.productListOrdersLength + 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.ADD_CLUSTER_TO_LIST_ORDERS:
      if (state.productListOrders.map((x) => x.id).indexOf(action.payload.id) === -1) {
        const numOfProducts = action.payload.products.length;
        return {
          ...state,
          productListOrders: [...state.productListOrders, action.payload],
          productListOrdersLength: state.productListOrdersLength + numOfProducts,
        };
      } else {
        const clusterIndex = state.productListOrders.map((x) => x.id).indexOf(action.payload.id);
        const productsToAdd = [];
        for (let product of action.payload.products) {
          if (state.productListOrders[clusterIndex].products.map((x) => x.id).indexOf(product.id) === -1) {
            productsToAdd.push(product);
          }
        }
        if (!!productsToAdd.length) {
          return {
            ...state,
            productListOrders: [
              ...state.productListOrders.slice(0, clusterIndex),
              {
                ...state.productListOrders[clusterIndex],
                products: [...state.productListOrders[clusterIndex].products, ...productsToAdd],
              },
              ...state.productListOrders.slice(clusterIndex + 1),
            ],
            productListOrdersLength: state.productListOrdersLength + productsToAdd.length,
          };
        } else {
          return {
            ...state,
          };
        }
      }
    case ProductsActions.ADD_CONFIGURABLE_CLUSTER_TO_LIST_ORDERS:
      let isDiffrentCluster = true;
      if (
        state.productListOrders.map((x) => x.id).indexOf(action.payload.id) !== -1 &&
        state.productListOrders[state.productListOrders.map((x) => x.id).indexOf(action.payload.id)].products.length ===
          action.payload.products.length
      ) {
        const clusterIndex = state.productListOrders.map((x) => x.id).indexOf(action.payload.id);
        for (let [i, product] of action.payload.products.entries()) {
          if (state.productListOrders[clusterIndex].products.map((x) => x.id).indexOf(product.id) === -1) {
            isDiffrentCluster = true;
            break;
          } else if (i === action.payload.products.length - 1) {
            isDiffrentCluster = false;
          }
        }
      }
      if (isDiffrentCluster) {
        const numOfProducts = action.payload.products.filter((product) => product.cluster !== null).length;
        return {
          ...state,
          productListOrders: [...state.productListOrders, action.payload],
          productListOrdersLength: state.productListOrdersLength + numOfProducts,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_LIST_ORDERS:
      if (state.productListOrders.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListOrders.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListOrders: state.productListOrders.filter((product, i) => {
            return i !== index;
          }),
          productListChangeMessage:
            'Product ' + state.productListOrders[index].name[0]?.value + ' was removed from the basket',
          productListOrdersLength: state.productListOrdersLength - 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_CLUSTER_ORDERS:
      if (state.productListOrders.map((x) => x.id).indexOf(action.payload.clusterId) !== -1) {
        const clusterIndex = state.productListOrders.map((x) => x.id).indexOf(action.payload.clusterId);
        if (state.productListOrders[clusterIndex].products.map((x) => x.id).indexOf(action.payload.productId) !== -1) {
          if (state.productListOrders[clusterIndex].products.length > 1) {
            const productIndex = state.productListOrders[clusterIndex].products
              .map((x) => x.id)
              .indexOf(action.payload.productId);
            return {
              ...state,
              productListOrders: [
                ...state.productListOrders.slice(0, clusterIndex),
                {
                  ...state.productListOrders[clusterIndex],
                  products: [
                    ...state.productListOrders[clusterIndex].products.filter((product, i) => {
                      return i !== productIndex;
                    }),
                  ],
                },
                ...state.productListOrders.slice(clusterIndex + 1),
              ],
              productListOrdersLength: state.productListOrdersLength - 1,
            };
          } else {
            return {
              ...state,
              productListOrders: state.productListOrders.filter((product, i) => {
                return i !== clusterIndex;
              }),
              productListOrdersLength: state.productListOrdersLength - 1,
            };
          }
        } else {
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.CLEAR_PRODUCT_LIST_ORDERS:
      return {
        ...state,
        productListOrders: [],
        productListOrdersLength: 0,
      };
    case ProductsActions.PRODUCT_TABLE_COLUMNS:
      return {
        ...state,
        productTableColumns: action.payload,
      };
    default:
      return state;
  }
}
