import { Action } from '@ngrx/store';
import { Company } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Companies] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Companies] Get Current Page Size';
export const GET_COMPANIES_COUNT = '[Companies] Get Companies Count';
export const LOAD_COMPANIES = '[Companies] Load Companies';
export const REFETCH_COMPANIES = '[Companies] Refetch Companies';
export const LOAD_COMPANIES_SUCCESS = '[Companies] Load Companies Success';
export const LOAD_COMPANIES_FAILURE = '[Companies] Load Companies Failure';

export const ADD_COMPANY = '[Companies] Add Company';
export const UPDATE_COMPANY = '[Companies] Update Company';
export const DELETE_COMPANY = '[Companies] Delete Company';

export const GET_GENERAL_FILTER = '[Companies] Get General Filter';

export const ADD_COMPANY_TO_LIST = '[Companies] Add Selected Company to List';
export const DELETE_COMPANY_FROM_LIST = '[Companies] Delete Company from List';
export const CLEAR_COMPANIES_LIST = '[Companies] Clear Companies List';

export const COMPANIES_TABLE_COLUMNS = '[Companies] Companies Table Columns';

export const COMPANIES_PAGE_INITIALIZED = '[Companies] Page Initialized';
export const COMPANIES_PAGE_DESTROYED = '[Companies] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetCompaniesCount implements Action {
  readonly type = GET_COMPANIES_COUNT;

  constructor(public payload: number) {}
}

export class LoadCompanies implements Action {
  readonly type = LOAD_COMPANIES;

  constructor(public payload: {}) {}
}

export class RefetchCompanies implements Action {
  readonly type = REFETCH_COMPANIES;

  constructor(public payload: {}) {}
}

export class LoadCompaniesSuccess implements Action {
  readonly type = LOAD_COMPANIES_SUCCESS;

  constructor(public payload: Company[]) {}
}

export class LoadCompaniesFailure implements Action {
  readonly type = LOAD_COMPANIES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddCompany implements Action {
  readonly type = ADD_COMPANY;

  constructor(public payload: Company) {}
}

// export class UpdateCompany implements Action {
//     readonly type = UPDATE_COMPANY;

//     constructor(public payload: {id: number, newCompany: Order}) {}
// }
export class DeleteCompany implements Action {
  readonly type = DELETE_COMPANY;

  constructor(public payload: number) {}
}

export class AddCompanyToList implements Action {
  readonly type = ADD_COMPANY_TO_LIST;

  constructor(public payload: Company) {}
}

export class DeleteCompanyFromList implements Action {
  readonly type = DELETE_COMPANY_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearCompaniesList implements Action {
  readonly type = CLEAR_COMPANIES_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class CompaniesTableColumns implements Action {
  readonly type = COMPANIES_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class CompaniesPageInitialized implements Action {
  readonly type = COMPANIES_PAGE_INITIALIZED;
}

export class CompaniesPageDestroyed implements Action {
  readonly type = COMPANIES_PAGE_DESTROYED;
}

export type CompaniesActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetCompaniesCount
  | LoadCompanies
  | RefetchCompanies
  | LoadCompaniesSuccess
  | LoadCompaniesFailure
  | AddCompany
  //| UpdateCompany
  | DeleteCompany
  | AddCompanyToList
  | DeleteCompanyFromList
  | ClearCompaniesList
  | CompaniesTableColumns
  | CompaniesPageInitialized
  | CompaniesPageDestroyed;
