import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as ProductsActions from '../../products/store/products.actions';
import * as fromApp from '../../store/app.reducer';
import { AuthService } from '../auth.service';
import { catchError, defer, filter, from, map, observable, Observable, of, switchMap, take, tap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private store: Store<fromApp.AppState>,
    private afAuth: AngularFireAuth,
    public router: Router,
    private mixpanelService: MixpanelService,
    private dialog: MatDialog,
  ) {}

  public readonly authLogin: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.LOGIN_START),
      switchMap((action: AuthActions.LoginStart) => {
        return from(this.afAuth.signInWithEmailAndPassword(action.payload.email, action.payload.password)).pipe(
          map((result) => {
            let user = JSON.parse(JSON.stringify(result.user));

            if (action.payload.persistentLogin) {
              localStorage.setItem('persistentLogin', 'true');
            }
            return new AuthActions.Login(user);
          }),
          catchError((error) => {
            return of(new AuthActions.LoginError(error));
          }),
        );
      }),
    );
  });

  public readonly autoLogin: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.AUTO_LOGIN),
      switchMap((action: AuthActions.AutoLogin) => {
        return this.store
          .select((store) => store.auth.activeTenant)
          .pipe(
            take(1),
            filter((activeTenant) => activeTenant === ''),
            map(() => new AuthActions.Login(action.payload)),
          );
      }),
    );
  });

  public readonly setTenants: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.LOGIN),
      switchMap((action: AuthActions.Login) => {
        return this.authService.getTenants(action.payload).pipe(
          map((data: any) => {
            this.store.dispatch(new AuthActions.SetUserId(data.id));
            if (data && data.tenants && data.tenants.length != 0) {
              if (this.router.url === '/sign-in') {
                this.router.navigate(['/companies']);

                this.mixpanelService.identify(data.email);
                this.mixpanelService.setPeople({ $email: data.email });
                this.mixpanelService.trackEvent('sign_in');
              }
              return new AuthActions.SetTenants(data.tenants);
            } else {
              return new AuthActions.LogoutStart();
            }
          }),
          catchError((error) => of(new AuthActions.LoginError(error))),
        );
      }),
    );
  });

  public readonly authLogout: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.LOGOUT_START),
      switchMap((action: AuthActions.LogoutStart) => {
        return from(this.afAuth.signOut()).pipe(
          map(() => {
            if (localStorage['persistentLogin']) {
              localStorage.removeItem('persistentLogin');
            }
            if (localStorage['taxes']) {
              localStorage.removeItem('taxes');
            }
            if (localStorage['selectedTab']) {
              localStorage.removeItem('selectedTab');
            }
            this.router.navigate(['sign-in']);

            if (this.dialog) {
              this.dialog.closeAll();
            }
            return new AuthActions.Logout();
          }),
        );
      }),
    );
  });
}
