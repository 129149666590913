import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { SurchargesQuery } from '../api/queries/surcharge-queries';
import { SurchargeResponse } from '../api/types/types';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(private apollo: Apollo) {}

  getProductSurchargeseQuery(variables: {}): QueryRef<SurchargeResponse> {
    return this.apollo.watchQuery<SurchargeResponse>({
      query: SurchargesQuery,
      variables: { input: variables },
    });
  }
}
