import { gql } from 'apollo-angular';

export const CreateCompanyMutation = gql`
  mutation CompanyCreate($input: CreateCompanyInput!) {
    companyCreate(input: $input) {
      id
      companyId
    }
  }
`;

export const UpdateCompanyMutation = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    companyUpdate(input: $input) {
      id
    }
  }
`;

export const UpdateCompanyAddressMutation = gql`
  mutation UpdateCompanyAddress($input: CompanyAddressUpdateInput!) {
    companyAddressUpdate(input: $input) {
      id
    }
  }
`;

export const CreateCompanyAddressMutation = gql`
  mutation CreateCompanyAddress($input: CompanyAddressCreateInput!) {
    companyAddressCreate(input: $input) {
      id
    }
  }
`;

export const DeleteCompanyMutation = gql`
  mutation DeleteCompany($id: Int!) {
    companyDelete(companyId: $id)
  }
`;
