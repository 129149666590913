import { gql } from 'apollo-angular';

export const RuleBuilderFragments = {
  array: gql`
    fragment arr on BusinessRuleArrayExpression {
      type
      numberArray: number
      stringArray: string
      arrayOperator: operator
    }
  `,
  string: gql`
    fragment str on BusinessRuleStringExpression {
      type
      string
      stringOperator: operator
      path
    }
  `,
  number: gql`
    fragment num on BusinessRuleNumberExpression {
      type
      number
      numberOperator: operator
      path
    }
  `,
  date: gql`
    fragment date on BusinessRuleDateExpression {
      type
      numberDate: number
      stringDate: string
      dateDate: date
      dateOperator: operator
      path
    }
  `,

  complex1: gql`
    fragment complex1 on BusinessRuleComplexExpression {
      type
      number
      complexOperator: operator
      action
      path
      field
      expressions {
        ...subexpression1
      }
    }
  `,

  complex2: gql`
    fragment complex2 on BusinessRuleComplexExpression {
      type
      number
      complexOperator: operator
      action
      path
      field
      expressions {
        ...subexpression2
      }
    }
  `,
  complex3: gql`
    fragment complex3 on BusinessRuleComplexExpression {
      type
      number
      complexOperator: operator
      action
      path
      field
      expressions {
        ...subexpression3
      }
    }
  `,
  complex4: gql`
    fragment complex4 on BusinessRuleComplexExpression {
      type
      number
      complexOperator: operator
      action
      path
      field
      expressions {
        ...subexpression4
      }
    }
  `,
  complex5: gql`
    fragment complex5 on BusinessRuleComplexExpression {
      type
      number
      complexOperator: operator
      action
      path
      field
    }
  `,
  subexpression1: gql`
    fragment subexpression1 on BusinessRuleSubExpressionGroup {
      operator
      expressions {
        ...arr
        ...num
        ...str
        ...date
        ...complex2
      }
    }
  `,
  subexpression2: gql`
    fragment subexpression2 on BusinessRuleSubExpressionGroup {
      operator
      expressions {
        ...arr
        ...num
        ...str
        ...date
        ...complex3
      }
    }
  `,
  subexpression3: gql`
    fragment subexpression3 on BusinessRuleSubExpressionGroup {
      operator
      expressions {
        ...arr
        ...num
        ...str
        ...date
        ...complex4
      }
    }
  `,
  subexpression4: gql`
    fragment subexpression4 on BusinessRuleSubExpressionGroup {
      operator
      expressions {
        ...arr
        ...num
        ...str
        ...date
        ...complex5
      }
    }
  `,
  expression: gql`
    fragment expression on BusinessRuleExpressionGroup {
      operator
      expressions {
        ...arr
        ...num
        ...str
        ...date
        ...complex1
      }
    }
  `,
  jdm: gql`
    fragment jdm on BusinessRule {
      id
      name {
        value
      }
      nodes {
        ... on BusinessRuleInput {
          id
          name
          type
        }
        ... on BusinessRuleOutput {
          id
          name
          type
        }
        ... on BusinessRuleDecisionTable {
          name
          id
          type
          content {
            hitPolicy
            inputs {
              name
              field
              type
              id
            }
            outputs {
              name
              field
              type
              id
            }
            rows {
              id
              inputs {
                columnId
                expression {
                  ...expression
                }
              }
              outputs {
                columnId
                expression {
                  ...expression
                }
                value
                localizedValue {
                  language
                  value
                }
                number
              }
            }
          }
        }
      }
      edges {
        id
        sourceId
        targetId
      }
    }
  `,
  decisionTable: gql`
    fragment decisionTable on BusinessRuleDecisionTable {
      id
      content {
        inputs {
          name
          field
          id
        }
        outputs {
          name
          field
          id
        }
        rows {
          id
          inputs {
            columnId
            expression {
              ...expression
            }
          }
          outputs {
            columnId
            expression {
              ...expression
            }
            number
            value
            localizedValue {
              value
            }
          }
        }
      }
    }
  `,
};
