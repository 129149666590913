import { FavoriteList } from '../../../shared/api/types/GraphQL';
import { TableColumns } from '../../../shared/api/types/enums';
import * as favorites from './favorites.actions';

export interface State {
  currentPage: number;
  currentPageSize: number;
  favoriteLists: FavoriteList[];
  selectedFavoriteListsList: FavoriteList[];
  loading: boolean;
  generalFilterValue: string;
  favoriteListsCount: number;
  productTableColumns: TableColumns;
  favoritesTableColumns: TableColumns;
}

const initialState: State = {
  currentPage: 1,
  currentPageSize: 5,
  favoriteLists: [],
  selectedFavoriteListsList: [],
  loading: true,
  generalFilterValue: '',
  favoriteListsCount: 0,
  productTableColumns: {
    displayedColumns: ['select', 'sku', 'name', 'supplier'],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: [],
  },
  favoritesTableColumns: {
    displayedColumns: ['select', 'id', 'userId', 'name', "slug", "default", "numberOfItems", "createdDate", "changedDate"],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: [],
  },
};

export function favoriteListsReducer(state: State = initialState, action: favorites.FavoriteListsActions) {
  switch (action.type) {
    case favorites.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case favorites.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };

    case favorites.REFETCH_FAVORITE_LISTS:
      return {
        ...state,
        loading: true,
      };

    case favorites.LOAD_FAVORITE_LISTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case favorites.LOAD_FAVORITE_LISTS_SUCCESS: {
      return {
        ...state,
        favoriteLists: action.payload.items,
        favoriteListsCount: action.payload.itemsFound,
        loading: false,
      };
    }

    case favorites.LOAD_FAVORITE_LISTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case favorites.ADD_FAVORITE_LIST_TO_SELECTED_LISTS:
      if (state.selectedFavoriteListsList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedFavoriteListsList: [...state.selectedFavoriteListsList, action.payload],
        };
      }
      return state;

    case favorites.DELETE_FAVORITE_LIST_FROM_SELECTED_LIST:
      if (state.selectedFavoriteListsList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedFavoriteListsList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedFavoriteListsList: state.selectedFavoriteListsList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;

    case favorites.CLEAR_SELECTED_FAVORITES_LISTS:
      return {
        ...state,
        selectedFavoriteListsList: [],
      };

    case favorites.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };

      case favorites.FAVORITES_TABLE_COLUMNS:
        return {
          ...state,
          favoritesTableColumns: action.payload,
        };  
    default:
      return state;
  }
}
