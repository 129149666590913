import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  BusinessRuleDeleteResponse,
  CarrierCreateResponse,
  CarrierListResponse,
  CarrierUpdateResponse,
} from '../shared/api/types/types';
import { CarrierCreateInput, CarrierUpdateInput, CarriersSearchInput } from '../shared/api/types/GraphQL';
import { BusinessRuleDeleteMutation } from '../shared/api/mutations/rule-builder-mutations';
import { CarriersList } from '../shared/api/queries/carrier-query';
import { HttpClient } from '@angular/common/http';
import { CarrierCreate, CarrierDelete, CarrierUpdate } from '../shared/api/mutations/carrier-mutations';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarriersService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  getCarriersList(input: CarriersSearchInput): QueryRef<CarrierListResponse> {
    return this.apollo.watchQuery<CarrierListResponse>({
      query: CarriersList,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  carrierDelete(id: number) {
    return this.apollo.mutate<Boolean>({
      mutation: CarrierDelete,
      variables: { id },
    });
  }

  carrierUpdate(variables: { id: number; input: CarrierUpdateInput }) {
    return this.apollo.mutate<CarrierUpdateResponse>({
      mutation: CarrierUpdate,
      variables,
    });
  }

  carrierCreate(variables: { input: CarrierCreateInput }) {
    return this.apollo.mutate<CarrierCreateResponse>({
      mutation: CarrierCreate,
      variables,
    });
  }

  checkLogoValidity(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next(true); // Image loaded successfully.
        observer.complete();
      };

      img.onerror = () => {
        observer.next(false); // Image failed to load.
        observer.complete();
      };
    });
  }
}
