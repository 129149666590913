import { Action } from '@ngrx/store';
import { Order } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Requests] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Requests] Get Current Page Size';
export const GET_REQUESTS_COUNT = '[Requests] Get Requests Count';
export const LOAD_REQUESTS = '[Requests] Load Requests';
export const LOAD_REJECTED = '[Requests] Load Rejected';
export const REFETCH_REQUESTS = '[Requests] Refetch Requests';
export const REFETCH_REJECTED = '[Requests] Refetch Rejected';
export const LOAD_REQUESTS_SUCCESS = '[Requests] Load Requests Success';
export const LOAD_REJECTED_SUCCESS = '[Requests] Load Rejected Success';
export const LOAD_REQUESTS_FAILURE = '[Requests] Load Requests Failure';
export const LOAD_REJECTED_FAILURE = '[Requests] Load Rejected Failure';

export const GET_CURRENT_PAGE_REJECTED = '[Requests] Get Current Page Rejected';
export const GET_CURRENT_PAGE_SIZE_REJECTED = '[Requests] Get Current Page Size Rejected';
export const GET_REQUESTS_COUNT_REJECTED = '[Requests] Get Requests Count Rejected';

export const ADD_REQUEST = '[Requests] Add Request';
export const ADD_REJECTED = '[Requests] Add Rejected';
export const UPDATE_REQUEST = '[Requests] Update Request';
export const DELETE_REQUEST = '[Requests] Delete Request';

export const ADD_REQUEST_TO_LIST = '[Requests] Add Selected Request to List';
export const DELETE_REQUEST_FROM_LIST = '[Requests] Delete Request from List';
export const CLEAR_REQUESTS_LIST = '[Requests] Clear Requests List';

export const GET_COLUMN_FILTERS = '[Requests] Get Column Filters';
export const GET_MULTISELECT_FILTERS = '[Requests] Get Multiselect Filters';
export const GET_GENERAL_FILTER = '[Requests] Get General Filter';
export const CLEAR_ALL_FILTERS = '[Requests] Clear All Filters';

export const PRODUCT_TABLE_COLUMNS = '[Requests] Product Table Columns';
export const ORDERS_TABLE_COLUMNS = '[Requests] Orders Table Columns';

export const REQUESTS_PAGE_INITIALIZED = '[Requests Page] Initialized';
export const REQUESTS_PAGE_DESTROYED = '[Requests Page] Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageRejected implements Action {
  readonly type = GET_CURRENT_PAGE_REJECTED;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSizeRejected implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE_REJECTED;

  constructor(public payload: number) {}
}

export class GetRequestsCount implements Action {
  readonly type = GET_REQUESTS_COUNT;

  constructor(public payload: number) {}
}

export class GetRequestsCountRejected implements Action {
  readonly type = GET_REQUESTS_COUNT_REJECTED;

  constructor(public payload: number) {}
}

export class LoadRequests implements Action {
  readonly type = LOAD_REQUESTS;

  constructor(public payload: {}) {}
}

export class LoadRejected implements Action {
  readonly type = LOAD_REJECTED;

  constructor(public payload: {}) {}
}

export class RefetchRequests implements Action {
  readonly type = REFETCH_REQUESTS;

  constructor(public payload: {}) {}
}

export class RefetchRejected implements Action {
  readonly type = REFETCH_REJECTED;

  constructor(public payload: {}) {}
}

export class LoadRequestsSuccess implements Action {
  readonly type = LOAD_REQUESTS_SUCCESS;

  constructor(public payload: Order[]) {}
}

export class LoadRejectedSuccess implements Action {
  readonly type = LOAD_REJECTED_SUCCESS;

  constructor(public payload: Order[]) {}
}

export class LoadRequestsFailure implements Action {
  readonly type = LOAD_REQUESTS_FAILURE;

  constructor(public payload: Error) {}
}

export class LoadRejectedFailure implements Action {
  readonly type = LOAD_REJECTED_FAILURE;

  constructor(public payload: Error) {}
}

export class AddRequest implements Action {
  readonly type = ADD_REQUEST;

  constructor(public payload: Order) {}
}

export class AddRejected implements Action {
  readonly type = ADD_REJECTED;

  constructor(public payload: Order) {}
}

export class UpdateRequest implements Action {
  readonly type = UPDATE_REQUEST;

  constructor(public payload: { id: number; updatedRequest: Order }) {}
}

export class DeleteRequest implements Action {
  readonly type = DELETE_REQUEST;

  constructor(public payload: number) {}
}

export class AddRequestToList implements Action {
  readonly type = ADD_REQUEST_TO_LIST;

  constructor(public payload: Order) {}
}

export class DeleteRequestFromList implements Action {
  readonly type = DELETE_REQUEST_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearRequestsList implements Action {
  readonly type = CLEAR_REQUESTS_LIST;
}

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}
export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(
    public payload: {
      manufacturer: string[];
      supplier: string[];
    },
  ) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class ClearAllFilters implements Action {
  readonly type = CLEAR_ALL_FILTERS;
}

export class ProductTableColumns implements Action {
  readonly type = PRODUCT_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class OrdersTableColumns implements Action {
  readonly type = ORDERS_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class RequestsPageInitialized implements Action {
  readonly type = REQUESTS_PAGE_INITIALIZED;
}

export class RequestsPageDestroyed implements Action {
  readonly type = REQUESTS_PAGE_DESTROYED;
}

export type RequestsActions =
  | GetCurrentPage
  | GetCurrentPageRejected
  | GetCurrentPageSize
  | GetCurrentPageSizeRejected
  | GetRequestsCount
  | GetRequestsCountRejected
  | LoadRequests
  | LoadRejected
  | RefetchRequests
  | RefetchRejected
  | LoadRequestsSuccess
  | LoadRejectedSuccess
  | LoadRequestsFailure
  | LoadRejectedFailure
  | AddRequest
  | AddRejected
  | UpdateRequest
  | DeleteRequest
  | AddRequestToList
  | DeleteRequestFromList
  | ClearRequestsList
  | GetColumnFilters
  | GetMultiselectFilters
  | GetGeneralFilter
  | ClearAllFilters
  | ProductTableColumns
  | ProductTableColumns
  | OrdersTableColumns
  | RequestsPageInitialized
  | RequestsPageDestroyed;
