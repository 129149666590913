import { gql } from 'apollo-angular';

export const AllCompaniesQuery = gql`
  query companies($input: CompanySearchArguments!) {
    companies(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        dateCreated
        companyId
        contacts {
          items {
            email
            firstName
            lastName
            middleName
            id
            contactId
          }
        }
        name
        addresses {
          firstName
          middleName
          lastName
          company
          email
          city
          country
          gender
          isDefault
          name
          number
          numberExtension
          mobile
          phone
          postalCode
          region
          street
          type
        }
      }
    }
  }
`;

export const CompanyListQuery = gql`
  query companies($name: String, $page: Int, $offset: Int, $sort: [CompanySortInput!], $managedCompaniesOnly: Boolean) {
    companies(
      input: { name: $name, page: $page, offset: $offset, sort: $sort, managedCompaniesOnly: $managedCompaniesOnly }
    ) {
      page
      itemsFound
      offset
      items {
        id
        dateCreated
        companyId
        name
      }
    }
  }
`;

export const CompaniesDropdownQuery = gql`
  query companies($input: CompanySearchArguments!) {
    companies(input: $input) {
      page
      itemsFound
      offset
      items {
        companyId
        name
      }
    }
  }
`;

export const CompanyQuery = gql`
  query company($companyId: Float!) {
    company(companyId: $companyId) {
      name
      contacts {
        itemsFound
        offset
        page
        items {
          email
          firstName
          id
          contactId
          lastName
          middleName
          mobile
          phone
        }
      }
      addresses {
        firstName
        middleName
        lastName
        company
        email
        code
        city
        country
        gender
        icp
        isDefault
        number
        numberExtension
        mobile
        phone
        postalCode
        region
        street
        type
      }
    }
  }
`;

export const CompanyDetailsQuery = gql`
  query company($companyId: Float, $id: Float) {
    company(companyId: $companyId, id: $id) {
      id
      companyId
      name
      taxNumber
      path
      dateCreated
      lastModifiedDate
      parentUsergroupId
    }
  }
`;

export const CompanyAdressesQuery = gql`
  query companyAddresses($type: AddressType!, $companyId: Float!) {
    addressesByCompanyId(type: $type, companyId: $companyId) {
      id
      firstName
      middleName
      company
      street
      number
      numberExtension
      postalCode
      city
      region
      country
      phone
      isDefault
    }
  }
`;
