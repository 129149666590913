import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';
import * as UsersActions from './users.actions';
import { UsersService } from '../../users.service';
import { UserTypesPlural } from '../../../../shared/api/types/enums';
import { CompanyListResponse, ContactListResponse, CustomerListResponse } from '../../../../shared/api/types/types';
import { UsergroupsResponse } from '../../../../shared/api/types/GraphQL';

type UserListResponse = ContactListResponse | CustomerListResponse | CompanyListResponse | UsergroupsResponse;

@Injectable()
export class UsersEffects {
  query: QueryRef<UserListResponse>;
  querySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private usersService: UsersService,
  ) {}

  public readonly loadUsers: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(UsersActions.LOAD_USERS),
      mergeMap((action: UsersActions.LoadUsers) => {
        this.defineQuery(action);
        this.querySub = this.query.valueChanges.pipe(
          map((data) => {
            return new UsersActions.LoadUsersSuccess({
              data: data.data[action.tab].items,
              itemsFound: data.data[action.tab].itemsFound,
              tab: action.tab,
            });
          }),
          catchError((error) => of(new UsersActions.LoadUsersFailure(error))),
        );
        return this.querySub;
      }),
    );
  });

  public readonly refetchUsers = createEffect(
    () =>
      this.actions.pipe(
        ofType(UsersActions.REFETCH_USERS),
        tap((action: UsersActions.RefetchUsers) => {
          this.query.resetLastResults();
          this.query.refetch({ input: action.payload });
        }),
      ),
    { dispatch: false },
  );

  private defineQuery(action: UsersActions.LoadUsers) {
    if (action.tab === UserTypesPlural.customers) {
      this.query = this.usersService.getCustomers(action.payload);
    } else if (action.tab === UserTypesPlural.contacts) {
      this.query = this.usersService.getContacts(action.payload);
    } else {
      this.query = this.usersService.getCompanies(action.payload);
    }
  }
}
