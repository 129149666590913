import { gql } from 'apollo-angular';
import * as globals from '../../globals';
import { TenderAddressFragment, TenderFragments } from '../fragments/tender.fragments';

export const TenderQuery = gql`
  query Tender($orderId: Int, $tenderId: String) {
    tender(orderId: $orderId, tenderId: $tenderId) {
      actionCode
      couponCode
      creditPoints
      date
      debtorId
      deliveryAddress {
        ...TenderAddress
      }
      isEditable
      email
      externalId
      extra3
      extra4
      firstName
      lastName
      middleName
      invoiceAddress {
        ...TenderAddress
      }
      invoiceUserId
      itemCount
      items {
        childItems {
          attributedSaleDiscount
          attributedSaleMargin
          costPrice
          customerDiscountPercentage
          customerPrice
          discountPercentage
          expectedDeliveryDate
          externalOrderitemId
          id
          marginAmount
          marginPercentage
          name
          notes
          orderItemId
          originalPrice
          price
          priceNet
          productId
          quantity
          saleMarginAmount
          saleMarginPercentage
          sku
          supplier
          supplierCode
          taxCode
          taxPercentage
          totalDiscountPercentage
          totalPrice
          totalPriceNet
          type
          valuePoints
          child: type
          ... on TenderMainItem {
            childItems {
              ...TenderChildItems
            }
            sum
            sumNet
            totalSum
            totalSumNet
            discountPercentageSum
            totalDiscountPercentageSum
            saleMarginAmountSum
            saleMarginPercentageSum
            attributedSaleDiscountSum
            attributedSaleMarginSum
          }
        }
        ...TenderMainItem
      }
      orderId
      incentivesApplied
      carriers {
        ...TenderCarrier
      }
      payMethods {
        ...TenderPaymethod
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      reference
      remarks
      shopId
      siteId
      source
      status
      tenderId
      total {
        ...TenderTotal
      }
      type
      userId
      valuePoints
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
  ${TenderFragments.paymethods}
`;
