<div class="sidenav-wrapper">
  <div class="nav-content">
    <ng-container *ngFor="let navCategory of navObject | keyvalue">
      <ng-container *ngIf="selectedTab === navCategory.key">
        <div class="nav-header">{{ navCategory.key }}</div>
        <ul class="main-nav">
          <li *ngFor="let item of navCategory.value; let i = index">
            <a
              (click)="trackMenuClick(item.value)"
              [routerLink]="item.path"
              [queryParams]="item.path === 'catalog' ? (catalogQueryParams | async) : {}"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: i === 0 ? true : false }"
              >{{ item.value }}</a
            >
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</div>
