import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, Observable, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as BusinessRulesActions from './business-rules.actions';
import * as fromApp from '../../store/app.reducer';
import { BusinessRulesService } from '../business-rules.service';
import { QueryRef } from 'apollo-angular';
import { BusinessRuleListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class BusinessRulesEffects {
  businessRulesQuery: QueryRef<BusinessRuleListResponse>;
  businessRulesQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private businessRulesService: BusinessRulesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadBusinessRules: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(BusinessRulesActions.LOAD_BUSINESS_RULES),
      withLatestFrom(this.store.select((state) => state.companies.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [BusinessRulesActions.LoadBusinessRules, boolean]) => {
        this.businessRulesQuery = this.businessRulesService.getBusinessRulesList(action.payload);
        this.businessRulesQuerySub = this.businessRulesQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new BusinessRulesActions.GetBusinessRulesCount(data.data.businessRules.itemsFound));
            return new BusinessRulesActions.LoadBusinessRulesSuccess(data.data.businessRules.items);
          }),
          catchError((error) => of(new BusinessRulesActions.LoadBusinessRulesFailure(error))),
        );
        return this.businessRulesQuerySub;
      }),
    );
  });

  public readonly refetchBusiinessRules = createEffect(
    () =>
      this.actions.pipe(
        ofType(BusinessRulesActions.REFETCH_BUSINESS_RULES),
        tap((action: BusinessRulesActions.RefetchBusinessRules) => {
          this.businessRulesQuery.resetLastResults();
          this.businessRulesQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(BusinessRulesActions.BUSINESS_RULES_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(BusinessRulesActions.BUSINESS_RULES_PAGE_DESTROYED)))),
      ),
    );
  }
}
