import { gql } from 'apollo-angular';
import * as globals from '../../globals';

export const CustomersDropdownQuery = gql`
  query customers($input: CustomerSearchArguments!) {
    customers(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        firstName
        middleName
        lastName
      }
    }
  }
`;

export const CustomerListQuery = gql`
  query customers($input: CustomerSearchArguments!) {
    customers(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        firstName
        customerId
        email
        middleName
        lastName
      }
    }
  }
`;

export const CustomerDetailsQuery = gql`
  query customer($customerId: Float, $id: Float) {
    customer(customerId: $customerId, id: $id) {
      id
      customerId
      firstName
      lastName
      middleName
      gender
      dateOfBirth
      primaryLanguage
      expires
      email
      isLoggedIn
      mailingList
      notes
      dateCreated
      lastModifiedDate
      iban
      bankAccount
      bic
      parentUsergroupId
    }
  }
`;

export const CustomerDetailsAttributesQuery = gql`
query customerDetailsAttributes ($customerId: Float,$attrPage: Int!, $attrOffset: Int!, $attrName: [String!], $attrType: [AttributeType!], $attrIsPublic: Boolean, $attrIsSearchable: Boolean) {
  customer(customerId: $customerId) {   
   attributeValues(filter: {page: $attrPage, offset: $attrOffset, name: $attrName, type: $attrType, isPublic: $attrIsPublic, isSearchable: $attrIsSearchable}) {
    itemsFound
    page
    items {
      attributeId
      textValue(language: "${globals.appLanguage}") {
        values
      }
      enumValue
      intValue
      decimalValue
      dateValue
      attributeDescription {
        id
        searchId
        name
        description(language: "${globals.appLanguage}") {
          value
        }
        type
        typeParam
        group
        isSearchable
        isPublic
      }
    }
   }
  }
}
`;

export const CustomerAddressQuery = gql`
  query customerAddresses($type: AddressType!, $customerId: Float!) {
    addressesByCustomerId(type: $type, customerId: $customerId) {
      id
      firstName
      middleName
      company
      street
      number
      numberExtension
      postalCode
      city
      region
      country
      phone
      isDefault
    }
  }
`;
