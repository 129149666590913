<div mat-dialog-title class="mat-h4">
  <mat-icon>warning</mat-icon>
  {{ title }}
</div>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onConfirm()">
    {{ confirmTxt }}
  </button>
  <button mat-button (click)="onDismiss()">{{ cancelTxt }}</button>
</div>
