import { Contact, Company, Customer } from '../../../../shared/api/types/GraphQL';
import { UserTypes, UserTypesPlural } from '../../../../shared/api/types/enums';
import * as users from './users.actions';

export interface State {
  currentPage: {
    contacts: number;
    companies: number;
    customers: number;
  };
  currentPageSize: {
    contacts: number;
    companies: number;
    customers: number;
  };
  users: Company[] | Customer[] | Contact[];
  selectedUsersList: {
    contacts: Contact[];
    companies: Company[];
    customers: Customer[];
  };
  loading: boolean;
  tab: UserTypesPlural;
  usersCount: number;
  usersTableColumns: {
    contacts: {};
    companies: {};
    customers: {};
  };
}

const initialState: State = {
  currentPage: {
    contacts: 1,
    companies: 1,
    customers: 1,
  },
  currentPageSize: {
    contacts: 5,
    companies: 5,
    customers: 5,
  },
  users: [],
  tab: UserTypesPlural.contacts,
  selectedUsersList: {
    contacts: [],
    companies: [],
    customers: [],
  },
  loading: true,
  usersCount: 0,
  usersTableColumns: {
    contacts: {
      displayedColumns: ['select', 'fullName', 'email', 'company', 'phone', 'source'],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: [],
      disabledColumns: ['contactId', 'fullName'],
    },
    companies: {
      displayedColumns: ['select', 'name', 'contacts', 'source'],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: [],
      disabledColumns: ['name'],
    },
    customers: {
      displayedColumns: ['select', 'name', 'email', 'source', 'customergroup'],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: [],
      disabledColumns: ['name'],
    },
  },
};

export function usersReducer(state: State = initialState, action: users.UsersActions) {
  switch (action.type) {
    case users.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          [state.tab]: action.payload,
        },
      };
    case users.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: {
          ...state.currentPageSize,
          [state.tab]: action.payload,
        },
      };

    case users.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };

    case users.REFETCH_USERS:
      return {
        ...state,
        loading: true,
      };

    case users.LOAD_USERS: {
      return {
        ...state,
        loading: true,
      };
    }

    case users.LOAD_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.data,
        usersCount: action.payload.itemsFound,
        loading: false,
      };
    }

    case users.LOAD_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case users.TAB_CHANGE:
      return {
        ...state,
        tab: action.payload,
      };

    case users.ADD_USER_TO_USER_LIST:
      const userType = determineUserType(state.tab);
      const idParameterName = `${userType}Id`; // companyId , contactId or customerId  because just ID is deprecated
      if (
        state.selectedUsersList[state.tab]
          .map((x: users.User) => x[idParameterName])
          .indexOf(action.payload[idParameterName]) === -1
      ) {
        const userList = [...state.selectedUsersList[state.tab], action.payload];
        return {
          ...state,
          selectedUsersList: {
            ...state.selectedUsersList,
            [state.tab]: userList,
          },
        };
      }
      return state;
    case users.DELETE_USER_FROM_USER_LIST:
      const selectedUserSubList: users.UserList = state.selectedUsersList[state.tab];
      const indexToDelete = selectedUserSubList.findIndex((user: users.User) => user.id === action.payload);
      if (indexToDelete !== -1) {
        const updatedUserSubList = [...selectedUserSubList];
        updatedUserSubList.splice(indexToDelete, 1);
        return {
          ...state,
          selectedUsersList: {
            ...state.selectedUsersList,
            [state.tab]: updatedUserSubList,
          },
        };
      }
      return state;

    case users.CLEAR_SELECTED_USERS_LIST:
      return {
        ...state,
        selectedUsersList: {
          ...state.selectedUsersList,
          [state.tab]: [],
        },
      };

    case users.USERS_TABLE_COLUMNS:
      return {
        ...state,
        usersTableColumns: {
          ...state.usersTableColumns,
          [state.tab]: action.payload,
        },
      };

    default:
      return state;
  }

  function determineUserType(tab: UserTypesPlural) {
    if (tab === UserTypesPlural.contacts) return UserTypes.contact;
    else if (tab === UserTypesPlural.customers) return UserTypes.customer;
    else return UserTypes.company;
  }
}
