import { gql } from 'apollo-angular';

export const UpdateTaxMutation = gql`
  mutation UpdateTax($id: ID!, $input: UpdateTaxInput!) {
    taxUpdate(id: $id, input: $input) {
      id
      code
      zone
      ratio
      shopId
      exportCode
    }
  }
`;

export const CreateTaxMutation = gql`
  mutation CreateTax($input: CreateTaxInput!) {
    taxCreate(input: $input) {
      id
    }
  }
`;

export const DeleteTaxMutation = gql`
  mutation DeleteTax($id: ID!) {
    taxDelete(id: $id) {
      id
    }
  }
`;
