<div class="header-wrapper">
  <div class="mat-headline-4 header">Surcharges</div>
</div>

<div class="table-wrapper" *ngIf="dataSource">
  <div class="btn-wrapper">
    <div class="mat-body-strong product-total" *ngIf="!selection.selected.length">{{ numberOfItems }} surcharges</div>
    <div mat-button *ngIf="selection.selected.length" class="mat-body-strong">
      Selected {{ selection.selected.length }} items:
    </div>
    <button
      mat-stroked-button
      color="primary"
      *ngIf="selection.selected.length && location === AppComponentLocation.product"
      [mat-dialog-close]="selection.selected"
    >
      Add to product
    </button>
    <!-- <div class="object-ml-auto">
          <button mat-icon-button class="object-ml-auto" (click)="openTableEditor();" matTooltip="Table editor"
            matTooltipPosition="above">
            <mat-icon matPrefix>calendar_view_month</mat-icon>
          </button>
        </div> -->
  </div>
  <table class="main-list-table full surcharges" mat-table [dataSource]="dataSource">
    <!-- Select Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="disableSelectAll"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="dialogData.includes(row.id)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Surcharge Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Surcharge code</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Surcharge</th>
      <td mat-cell *matCellDef="let element">{{ element.name[0].value }}</td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amountFee">
      <th mat-header-cell *matHeaderCellDef>Amount, €</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === SurchargeType[SurchargeType.FlatFee]">
          {{ element.value }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amountPercentage">
      <th mat-header-cell *matHeaderCellDef>Amount, %</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === SurchargeType[SurchargeType.Percentage]">
          {{ element.value }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Tax Column -->
    <ng-container matColumnDef="tax">
      <th mat-header-cell *matHeaderCellDef>Tax</th>
      <td mat-cell *matCellDef="let element">
        {{ SurchargeTaxCodes[element.taxCode] }}
      </td>
    </ng-container>

    <!-- Country Column -->
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>Country</th>
      <td mat-cell *matCellDef="let element">
        {{ regionNames.of(element.taxZone) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      [ngClass]="{ disabled: dialogData.includes(element.id) }"
    ></tr>
  </table>
</div>

<mat-paginator
  [length]="numberOfItems"
  [pageSize]="itemsPerPage"
  [pageSizeOptions]="[5, 10, 25, 100]"
  aria-label="Select page of surcharges"
>
</mat-paginator>
