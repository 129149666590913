import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';

import {
  ProductCreateResponse,
  ProductDeleteResponse,
  ProductListResponse,
  ProductResponse,
} from '../shared/api/types/types';
import {
  AllProductsQuery,
  CatalogProductDetailsQuery,
  ClusterTypeQuery,
  ConfigurableClusterQuery,
  LinearClusterQuery,
  NormalClasterQuery,
  ProductDocuments,
  ProductsByProductCode,
  ProductSpecifications,
  ProductVideos,
} from '../shared/api/queries/prouct-queries';
import { ProductCreateMutation, ProductDeleteMutation } from '../shared/api/mutations/product-mutations';
import { CreateProductInput } from '../shared/api/types/GraphQL';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  imgLoading: boolean = true;

  constructor(private apollo: Apollo) {}

  getAllProducts(variables: {}): QueryRef<ProductListResponse> {
    return this.apollo.watchQuery<ProductListResponse>({
      query: AllProductsQuery,
      variables,
    });
  }

  getProductsByProductCode(variables: {}): QueryRef<ProductListResponse> {
    return this.apollo.watchQuery<ProductListResponse>({
      query: ProductsByProductCode,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getCatalogProductDetail(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: CatalogProductDetailsQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getClusterType(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: ClusterTypeQuery,
      variables,
    });
  }

  getNormalCluster(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: NormalClasterQuery,
      variables,
    });
  }

  getLinearCluster(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: LinearClusterQuery,
      variables,
    });
  }

  getConfigurableCluster(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: ConfigurableClusterQuery,
      variables,
    });
  }

  getProductSpecifications(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: ProductSpecifications,
      variables,
      fetchPolicy: "no-cache"
    });
  }

  getProductDocuments(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: ProductDocuments,
      variables,
      fetchPolicy: "no-cache"
    });
  }

  getProductVideos(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: ProductVideos,
      variables,
      fetchPolicy: "no-cache"
    });
  }

  productDelete(productId: number) {
    return this.apollo.mutate<ProductDeleteResponse>({
      mutation: ProductDeleteMutation,
      variables: { productId: productId },
    });
  }

  productCreate(input: CreateProductInput) {
    return this.apollo.mutate<ProductCreateResponse>({
      mutation: ProductCreateMutation,
      variables: { input },
    });
  }
}
