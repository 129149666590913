import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  FavoriteList,
  FavoriteListsCreateInput,
  FavoriteListsItemsInput,
  FavoriteListsResponse,
  FavoriteListsUpdateInput,
} from '../../shared/api/types/GraphQL';
import { FavoriteListDetailsQuery, FavoriteListsQuery } from '../../shared/api/queries/favorites-queries';
import {
  CreateFavoriteListMutation,
  UpdateFavoriteListMutation,
  AddProductsToFavoriteListMutation,
  RemoveProductsFromFavoriteListMutation,
  DeleteFavoriteListMutation,
} from '../../shared/api/mutations/favorites-mutations';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
  constructor(private apollo: Apollo) {}

  getFavoriteLists(variables: {}): QueryRef<FavoriteListsResponse> {
    return this.apollo.watchQuery<FavoriteListsResponse>({
      query: FavoriteListsQuery,
      variables: { input: variables },
    });
  }

  getFavoriteListDetails(id: string): QueryRef<FavoriteList> {
    return this.apollo.watchQuery<FavoriteList>({
      query: FavoriteListDetailsQuery,
      variables: { id },
    });
  }

  deleteFavoriteList(id: string) {
    return this.apollo.mutate<Boolean>({
      mutation: DeleteFavoriteListMutation,
      variables: { id },
    });
  }

  updateFavoriteList(id: string, variables: FavoriteListsUpdateInput) {
    return this.apollo.mutate<any>({
      mutation: UpdateFavoriteListMutation,
      variables: { id, input: variables },
    });
  }
  createFavoriteList(variables: FavoriteListsCreateInput) {
    return this.apollo.mutate<any>({
      mutation: CreateFavoriteListMutation,
      variables: { input: variables },
    });
  }

  addProductsToFavoriteList(id: string, variables: FavoriteListsItemsInput) {
    return this.apollo.mutate<any>({
      mutation: AddProductsToFavoriteListMutation,
      variables: { id, input: variables },
    });
  }

  removeProductsFromFavoriteList(id: string, variables: FavoriteListsItemsInput) {
    return this.apollo.mutate<any>({
      mutation: RemoveProductsFromFavoriteListMutation,
      variables: { id, input: variables },
    });
  }
}
