import { gql } from 'apollo-angular';
import * as globals from '../../globals';

export const UpdateProductMutation = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    productUpdate(input:$input) {
        categoryId
        name(language: "${globals.appLanguage}") {
            value
        }
        description(language: "${globals.appLanguage}") {
            value
        }
        shortDescription(language: "${globals.appLanguage}") {
            value
        }
        sku
        status
        supplier
        supplierCode
        manufacturerCode
        eanCode
        taxCode
        originalPrice
        unit
        minimumQuantity
        manufacturer
        costPrice
        suggestedPrice
        package
        shortName
        productId
    }
}`;

export const ProductDeleteMutation = gql`
  mutation ProductDelete($productId: Int!) {
    productDelete(productId: $productId)
  }
`;

export const ProductCreateMutation = gql`
  mutation ProductCreate($input:CreateProductInput!) {
    productCreate(input: $input) {
        productId
        name (language: "${globals.appLanguage}") {
            value
          }
    }
  }
`;
