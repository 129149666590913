import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MetaReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { AngularMaterialModule } from './angular-material.module';
import { CoreModule } from './core.module';

import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TableEditorDialogComponent } from './shared/components/table-editor-dialog/table-editor-dialog.component';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';

import * as fromApp from './store/app.reducer';
import { environment } from 'src/environments/environment';
import * as fromLocalStorage from './shared/store/reducers';

import { QuotesEffects } from './orders/store/quotes/quotes.effects';
import { OrdersEffects } from './orders/store/orders/orders.effects';
import { ProductsEffects } from './products/store/products.effects';
import { RequestsEffects } from './orders/store/requests/requests.effects';
import { CatalogEffects } from './catalog/store/catalog.effects';
import { AuthEffects } from './auth/store/auth.effects';
import { BusinessRulesEffects } from './business-rules/store/business-rules.effects';
import { CompaniesEffects } from './companies/store/companies/companies.effects';
import { MyCompaniesEffects } from './companies/store/my-companies/my-companies.effects';
import { FileDialogComponent } from './shared/components/file-dialog/file-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SurchargesComponent } from './shared/components/surcharges/surcharges.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { UsersEffects } from './crm/users/store/users/users.effects';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { CarriersEffects } from './carriers/store/carriers.effects';
import { TaxesEffects } from './e-business/taxes/store/taxes.effects';
import { FavoritesEffects } from './crm/favorites/store/favorites.effects';
import { WarehousesEffects } from './warehouses/store/warehouses.effects';

const metaReducers: Array<MetaReducer<any, any>> = [
  fromLocalStorage.localStorageSyncReducer,
  fromApp.resetAppStateOnLogout,
];

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    TableEditorDialogComponent,
    MessageDialogComponent,
    SnackBarComponent,
    ConfirmDialogComponent,
    FileDialogComponent,
    PageNotFoundComponent,
    SurchargesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    GraphQLModule,
    StoreModule.forRoot(fromApp.appReducer, { metaReducers }),
    EffectsModule.forRoot([
      QuotesEffects,
      OrdersEffects,
      RequestsEffects,
      ProductsEffects,
      CompaniesEffects,
      MyCompaniesEffects,
      CatalogEffects,
      AuthEffects,
      UsersEffects,
      BusinessRulesEffects,
      CarriersEffects,
      TaxesEffects,
      FavoritesEffects,
      WarehousesEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatNativeDateModule,
  ],
  bootstrap: [AppComponent],
})

//providers: [], --->  IN CORE MODULE
export class AppModule {
  // constructor(iconRegistry: MatIconRegistry) {
  //   iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  // }
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('theme-default', 'amp-app');
  }
}
