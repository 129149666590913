import { Injectable } from '@angular/core';
import { Apollo, QueryRef, TypedDocumentNode } from 'apollo-angular';
import { ContactsListQuery } from '../../shared/api/queries/contact-query';
import { CustomerListQuery } from '../../shared/api/queries/customer-queries';
import { CompanyListResponse, ContactListResponse, CustomerListResponse } from '../../shared/api/types/types';
import { AllCompaniesQuery } from '../../shared/api/queries/company-queries';
import {
  CreateCompanyInput,
  CreateContactInput,
  CustomerInput,
  UsergroupsResponse,
} from '../../shared/api/types/GraphQL';
import { CreateContactMutation, DeleteContactMutation } from '../../shared/api/mutations/contact-mutations';
import { CreateCustomerMutation, DeleteCustomerMutation } from '../../shared/api/mutations/customer-mutations';
import { CreateCompanyMutation, DeleteCompanyMutation } from '../../shared/api/mutations/company-mutations';
import { UserTypesPlural } from '../../shared/api/types/enums';
import { UsergroupsListQuery } from '../../shared/api/queries/usergroup-queries';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private apollo: Apollo) {}

  getContacts(variables: {}): QueryRef<ContactListResponse> {
    return this.apollo.watchQuery<ContactListResponse>({
      query: ContactsListQuery,
      variables: { input: variables },
    });
  }
  getCustomers(variables: {}): QueryRef<CustomerListResponse> {
    return this.apollo.watchQuery<CustomerListResponse>({
      query: CustomerListQuery,
      variables: { input: variables },
    });
  }
  getCompanies(variables: {}): QueryRef<CompanyListResponse> {
    return this.apollo.watchQuery<CompanyListResponse>({
      query: AllCompaniesQuery,
      variables: { input: variables },
    });
  }

  getUsergroups(variables: {}): QueryRef<UsergroupsResponse> {
    return this.apollo.watchQuery<UsergroupsResponse>({
      query: UsergroupsListQuery,
      variables: { input: variables },
    });
  }

  private createContact(variables: CreateContactInput) {
    return this.apollo.mutate<any>({
      mutation: CreateContactMutation,
      variables: { input: variables },
    });
  }

  private createCustomer(variables: CustomerInput) {
    return this.apollo.mutate<any>({
      mutation: CreateCustomerMutation,
      variables: { input: variables },
    });
  }

  private createCompany(variables: CreateCompanyInput) {
    return this.apollo.mutate<any>({
      mutation: CreateCompanyMutation,
      variables: { input: variables },
    });
  }

  deleteUser(id: number, userType: UserTypesPlural) {
    let mutation: TypedDocumentNode;
    switch (userType) {
      case UserTypesPlural.customers:
        mutation = DeleteCustomerMutation;
        break;
      case UserTypesPlural.companies:
        mutation = DeleteCompanyMutation;
        break;
      case UserTypesPlural.contacts:
        mutation = DeleteContactMutation;
        break;
      default:
        throw new Error('Invalid user type');
    }

    return this.apollo.mutate<any>({
      mutation,
      variables: { id },
    });
  }

  createUser(selectedTab: UserTypesPlural, user: CreateCompanyInput | CustomerInput | CreateContactInput) {
    if (selectedTab === UserTypesPlural.customers) {
      return this.createCustomer(user as CustomerInput);
    } else if (selectedTab === UserTypesPlural.contacts) {
      return this.createContact(user as CreateContactInput);
    } else {
      return this.createCompany(user as CreateCompanyInput);
    }
  }
}
