import { gql } from 'apollo-angular';

export const UpdateContactMutation = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    contactUpdate(input: $input) {
      firstName
      lastName
      middleName
      gender
      dateOfBirth
      primaryLanguage
    }
  }
`;

export const CreateContactMutation = gql`
  mutation ContactCreate($input: CreateContactInput!) {
    contactCreate(input: $input) {
      id
      contactId
    }
  }
`;

export const DeleteContactMutation = gql`
  mutation DeleteContact($id: Int!) {
    contactDelete(contactId: $id)
  }
`;
