<div class="amp-app main-wrapper">
  <div class="side-bar" *ngIf="!showSidebar">
    <div class="logo" (click)="toggleSidnavFixed()">
      <img src="/assets/images/logo_BLUE.svg" alt="propeller-logo" />
    </div>
    <ul class="icon-nav" (mouseover)="!sidenavOpen ? sidenav.open() : ''">
      <li
        *ngFor="let item of mainIconNavItems; let i = index"
        (click)="onChangeTab(item.value)"
        [class.active]="item.value === selectedTab"
        [matTooltip]="item.value"
        matTooltipPosition="above"
        matTooltipClass="navigation-tooltip"
      >
        <mat-icon>{{ item.icon }}</mat-icon>
      </li>
    </ul>
    <span class="sidebar-spacer" (mouseover)="!sidenavOpen ? sidenav.open() : ''"></span>
    <ul class="floating-actions">
      <li class="floating-actions-icon" (click)="toggleFloatingActionsPopup()" #floatingActionsButton>
        <mat-icon>add_circle_outline</mat-icon>
      </li>
    </ul>
    <span class="sidebar-spacer" (mouseover)="!sidenavOpen ? sidenav.open() : ''"></span>
    <ul class="settings">
      <li (click)="onLogout()">
        <mat-icon>power_settings_new</mat-icon>
      </li>
      <li>
        <mat-icon>tune</mat-icon>
      </li>
      <li class="user-settings" (click)="toggleTenantsPopup()" #tenantsButton>
        <mat-icon>person</mat-icon>
      </li>
    </ul>
    <div class="tenants-popup" *ngIf="tenantsOpened | async" #tenantsPopup>
      <div
        *ngFor="let tenant of tenants | async"
        (click)="switchTenant(tenant.tenant)"
        class="mat-caption"
        [ngClass]="{ activeTenant: tenant.tenant === (activeTenant | async) }"
      >
        <span *ngIf="tenant.tenant === (activeTenant | async)">Current view: </span>
        {{ tenant.name }}
      </div>
    </div>
    <div class="floating-actions-popup" *ngIf="floatingActionsOpened | async" #floatingActions>
      <div (click)="onNewOrder('quotation')" class="mat-caption">New quote</div>
      <div (click)="onNewOrder('dropshipment')" class="mat-caption">New order</div>
    </div>
  </div>
  <mat-sidenav-container class="main-container" hasBackdrop="false" [ngClass]="{ 'no-sidebar': showSidebar }">
    <mat-sidenav #sidenav [mode]="sidenavOpen ? 'side' : 'over'" (mouseleave)="!sidenavOpen ? sidenav.close() : ''">
      <app-side-nav></app-side-nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- <div class="theme-toggle">
        <mat-slide-toggle [formControl]="toggleControl" class="object-mr-1">
        </mat-slide-toggle>
      </div> -->
      <div class="content">
        <!-- <app-ng-dynamic-breadcrumb
              [bgColor]="'transparent'"
              [fontSize]="'12px'"
              [fontColor]="'var(--txt-color-secondary)'"
              [lastLinkColor]="'var(--txt-color-secondary)'"
              [symbol]="' / '"></app-ng-dynamic-breadcrumb> -->
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
