// @angular
import { OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { QueryRef } from 'apollo-angular';

// @rxjs
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

// store
import { Store } from '@ngrx/store';
import { Tenant } from './auth/store/auth.reducer';
import * as fromApp from './store/app.reducer';
import * as AuthActions from './auth/store/auth.actions';

// services
import { SidenavService } from './side-nav/sidenav.service';
import { TaxesService } from './e-business/taxes/taxes.service';

// shared
import { TaxesQueryResponce } from './shared/api/types/types';
import * as globals from './shared/globals';
import { SnackBarService } from './shared/components/snack-bar/snack-bar.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title: string = 'the-amp-app-v1';

  selectedTab: string;
  sidenavOpen: boolean = false;
  sidenavOpenMode: string = 'over';
  showSidebar: boolean;

  boAccess: boolean = environment.boAccess || false;
  mainIconNavItems: { value: string; icon: string }[] = [];

  error: Error;

  @ViewChild('sidenav') sidenav: MatSidenav;

  toggleControl = new UntypedFormControl(false);
  @HostBinding('class') className = '';

  tenants: Observable<Tenant[]>;
  activeTenant: Observable<string>;
  tenantsOpenedSub: Subscription;
  tenantsOpened = new BehaviorSubject<boolean>(false);
  @ViewChild('tenantsPopup') tenantsPopup: ElementRef;
  @ViewChild('tenantsButton') tenantsButton: ElementRef;

  @ViewChild('floatingActions') floatingActions: ElementRef;
  @ViewChild('floatingActionsButton') floatingActionsButton: ElementRef;
  floatingActionsOpened = new BehaviorSubject<boolean>(false);
  floatingActionsOpenedSub: Subscription;
  openedTenantsListener: () => void;
  openedActionsListener: () => void;

  taxesQuery: QueryRef<TaxesQueryResponce>;
  taxesQuerySub: Subscription;

  timeoutId;

  constructor(
    private sidenavService: SidenavService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private overlay: OverlayContainer,
    private renderer: Renderer2,
    private store: Store<fromApp.AppState>,
    private taxesService: TaxesService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    if (!this.boAccess) this.mainIconNavItems = [globals.boNavItems[0]];
    else this.mainIconNavItems = globals.boNavItems;

    this.tenants = this.store.select((store) => store.auth.tenants);
    this.activeTenant = this.store.select((store) => store.auth.activeTenant);

    this.toggleControl.valueChanges.subscribe((darkMode) => {
      const darkClassName = 'theme-alternate';
      const lightClassName = 'theme-default';
      this.className = darkMode ? darkClassName : lightClassName;
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(darkClassName);
        this.overlay.getContainerElement().classList.remove(lightClassName);
      } else {
        this.overlay.getContainerElement().classList.add(lightClassName);
        this.overlay.getContainerElement().classList.remove(darkClassName);
      }
    });

    this.sidenavService.currentTab.subscribe((tabName) => {
      this.selectedTab = tabName;
    });

    this.router.events
      .pipe(
        tap((e) => {
          if (e instanceof NavigationEnd) {
            this.showSidebar =
              e.url.includes('sign-in') ||
              e.url.includes('orders/') ||
              e.url.includes('quotes/') ||
              e.url.includes('requests/');
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        }),
      )
      .subscribe((title: any) => {
        this.titleService.setTitle(title);
      });

    this.tenantsOpenedSub = this.tenantsOpened.subscribe((open) => {
      if (open) {
        this.openedTenantsListener = this.renderer.listen('window', 'click', (e: Event) => {
          if (
            !this.tenantsPopup.nativeElement.contains(e.target) &&
            !this.tenantsButton.nativeElement.contains(e.target)
          ) {
            this.tenantsOpened.next(false);
          }
        });
      } else if (this.openedTenantsListener) {
        this.openedTenantsListener();
      }
    });

    this.floatingActionsOpenedSub = this.floatingActionsOpened.subscribe((open) => {
      if (open) {
        this.openedActionsListener = this.renderer.listen('window', 'click', (e: Event) => {
          if (
            !this.floatingActions.nativeElement.contains(e.target) &&
            !this.floatingActionsButton.nativeElement.contains(e.target)
          ) {
            this.floatingActionsOpened.next(false);
          }
        });
      } else if (this.openedActionsListener) {
        this.openedActionsListener();
      }
    });

    // query taxes and store in local storage for app use
    if (!localStorage['taxes']) {
      this.taxesQuery = this.taxesService.getTaxes({
        input: { zone: globals.appTaxZone, shopId: globals.shopId },
      });

      this.taxesQuerySub = this.taxesQuery.valueChanges
        .pipe(
          take(1),
          map((data) => data.data.taxes.items),
        )
        .subscribe({
          next: (taxes) => {
            localStorage.setItem('taxes', JSON.stringify(taxes));
          },
          error: (error) => {
            this.error = error.graphQLErrors;
            this.error.message =
              (error.graphQLErrors[0].extensions ? error.graphQLErrors[0].extensions.messages[0] : undefined) ||
              error.graphQLErrors[0].message ||
              error;
            this.snackBarService.openSnackBar(this.error.message, '', 'error');
          },
        });
    }
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(() => {
      this.store.dispatch(new AuthActions.LogoutStart());
      this.closeSidenav();
    }, 1800000);
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity() {
    if (!localStorage['persistentLogin']) {
      clearTimeout(this.timeoutId);
      this.checkTimeOut();
    }
  }

  switchTenant(tenantId) {
    this.store.dispatch(new AuthActions.SetActiveTenant(tenantId));
    window.location.href = window.location.pathname;
  }

  toggleSidnavFixed() {
    this.sidenavOpen = !this.sidenavOpen;
    this.sidenavOpen ? this.openSidenav() : this.closeSidenav();
  }

  private openSidenav() {
    this.sidenav.open();
    this.sidenavOpenMode = 'push';
  }

  private closeSidenav() {
    this.sidenav.close();
    this.sidenavOpenMode = 'over';
  }

  onChangeTab(tabName: string) {
    this.sidenavService.changeTab(tabName);
    localStorage.setItem('selectedTab', tabName);
  }

  toggleTenantsPopup() {
    this.tenantsOpened.next(!this.tenantsOpened.getValue());
  }

  toggleFloatingActionsPopup() {
    this.floatingActionsOpened.next(!this.floatingActionsOpened.getValue());
  }

  onNewOrder(orderType: string) {
    let url: string;
    if (orderType === 'dropshipment') {
      url = this.router.serializeUrl(this.router.createUrlTree(['sp/orders/new']));
    } else if (orderType === 'quotation') {
      url = this.router.serializeUrl(this.router.createUrlTree(['sp/quotes/new']));
    }
    window.open(url, '_blank');
  }

  onLogout() {
    this.store.dispatch(new AuthActions.LogoutStart());
    this.closeSidenav();
  }

  ngOnDestroy(): void {
    this.tenantsOpenedSub.unsubscribe();
    this.floatingActionsOpenedSub.unsubscribe();
  }
}
