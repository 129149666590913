import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';

import { ProductListResponse } from '../shared/api/types/types';
import { CatalogQuery } from '../shared/api/queries/prouct-queries';

@Injectable({ providedIn: 'root' })
export class CatalogService {
  imgLoading: boolean = true;

  constructor(private apollo: Apollo) {}

  getCatalogProducts(variables: {}): QueryRef<ProductListResponse> {
    return this.apollo.watchQuery<ProductListResponse>({
      query: CatalogQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }
}
