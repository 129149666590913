import * as AuthActions from './auth.actions';

export interface Tenant {
  id: number;
  tenant: string;
  name: string;
  default: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface State {
  isInitializing: boolean;
  loading: boolean;
  user: {};
  userId: number;
  tenants: Tenant[];
  activeTenant: string;
  error: Error;
}

const initialState: State = {
  isInitializing: true,
  loading: false,
  user: null,
  userId: 0,
  tenants: [],
  activeTenant: '',
  error: null,
};

export function authReducer(state = initialState, action: AuthActions.AuthActions) {
  switch (action.type) {
    case AuthActions.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case AuthActions.LOGIN:
      return {
        ...state,
        user: action.payload,
        isInitializing: false,
        loading: true,
      };
    case AuthActions.AUTO_LOGIN:
      return {
        ...state,
        user: action.payload,
        isInitializing: false,
        loading: false,
      };
    case AuthActions.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        user: null,
        tenants: [],
        activeTenant: '',
        isInitializing: false,
      };
    case AuthActions.SET_TENANTS:
      const defaultTenant = action.payload.find((item) => item.default === true) || action.payload[0];
      return {
        ...state,
        tenants: action.payload,
        activeTenant: defaultTenant.tenant,
        loading: false,
      };
    case AuthActions.SET_ACTIVE_TENANT:
      return {
        ...state,
        activeTenant: action.payload,
        loading: false,
      };
    case AuthActions.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
}
