import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/companies', pathMatch: 'full' },
  {
    path: 'ebusiness/incentives',
    loadChildren: () => import('./business-rules/business-rules.module').then((mod) => mod.BusinessRulesModule),
  },
  {
    path: 'order-management/carriers',
    loadChildren: () => import('./carriers/carriers.module').then((mod) => mod.CarriersModule),
  },
  {
    path: 'order-management/warehouses',
    loadChildren: () => import('./warehouses/warehouses.module').then((mod) => mod.WarehousesModule),
  },
  {
    path: 'rule-builder',
    loadChildren: () => import('./rule-builder/rule-builder.module').then((mod) => mod.RuleBuilderModule),
  },
  {
    path: 'catalog',
    loadChildren: () => import('./catalog/catalog.module').then((mod) => mod.CatalogModule),
  },
  {
    path: 'pim/products-and-clusters',
    loadChildren: () => import('./products/products.module').then((mod) => mod.ProductsModule),
  },
  {
    path: 'pim/products-and-clusters/product',
    loadChildren: () =>
      import('./products/product-details/product-details.module').then((mod) => mod.ProductDetailsModule),
  },
  {
    path: 'crm/favorites',
    loadChildren: () => import('./crm/favorites/favorites.module').then((mod) => mod.FavoritesModule),
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./auth/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: 'crm/users',
    loadChildren: () => import('./crm/users/users.module').then((mod) => mod.UsersModule),
  },
  {
    path: 'crm/users/details',
    loadChildren: () => import('./crm/users/user-details/user-details.module').then((mod) => mod.UserDetailsModule),
  },
  {
    path: 'companies',
    loadChildren: () => import('./companies/companies.module').then((mod) => mod.CompaniesModule),
  },
  {
    path: '',
    loadChildren: () => import('./orders/order-edit/order-edit.module').then((m) => m.OrderEditModule),
  },
  {
    path: '',
    loadChildren: () => import('./orders/orders.module').then((mod) => mod.OrdersModule),
  },
  {
    path: 'ebusiness/taxes',
    loadChildren: () => import('./e-business/taxes/taxes.module').then((mod) => mod.TaxesModule),
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Sales Portal | Page not found',
    },
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
