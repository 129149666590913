import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MixpanelService } from '../shared/services/mixpanel.service';
import { SidenavService } from './sidenav.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  selectedTab: string;
  catalogQueryParams: Observable<{}>;

  navObject: Record<string, { value: string; path: string }[]> = {
    Sales: [
      {
        value: 'Companies',
        path: 'companies',
      },
      {
        value: 'Requests',
        path: 'sp/requests',
      },
      {
        value: 'Quotes',
        path: 'sp/quotes',
      },
      {
        value: 'Orders',
        path: 'sp/orders',
      },
      {
        value: 'Logistics',
        path: 'sp/logistics',
      },
      {
        value: 'Catalogue', // should we change this one as in the specs (Products) or it should stay "Catalog" ?
        path: `catalog`,
      },
    ],
    PIM: [
      {
        value: 'Products & Clusters',
        path: 'pim/products-and-clusters',
      },
      {
        value: 'Category',
        path: 'pim/category',
      },
      {
        value: 'Spare parts',
        path: 'pim/spare-parts',
      },
      {
        value: 'Bundles',
        path: 'pim/bundles',
      },
      {
        value: 'Filterset',
        path: 'pim/filterset',
      },
      {
        value: 'Product Attributes',
        path: 'pim/product-attributes',
      },
      {
        value: 'Query Attributes',
        path: 'pim/query-attributes',
      },
    ],
    CRM: [
      {
        value: 'Users',
        path: 'crm/users',
      },
      {
        value: 'Favorites',
        path: 'crm/favorites',
      },
      {
        value: 'Orderlists',
        path: 'crm/orderlists',
      },
      {
        value: 'Authorizations',
        path: 'crm/authorizations',
      },
      {
        value: 'User Attributes',
        path: `crm/user-attributes`,
      },
    ],
    'Order Management': [
      {
        value: 'Orders',
        path: `bo/orders`,
      },
      {
        value: 'Baskets',
        path: `order-management/baskets`,
      },
      {
        value: 'Carriers',
        path: `order-management/carriers`,
      },
      {
        value: 'Warehouses',
        path: `order-management/warehouses`,
      },
      {
        value: 'Paymethods',
        path: `order-management/paymethods`,
      },
      {
        value: 'Invoices',
        path: `order-management/invoices`,
      },
      {
        value: 'Order statuses',
        path: `order-management/order-statuses`,
      },
    ],
    Pricing: [
      {
        value: 'Pricing',
        path: `pricing`,
      },
    ],
    'E-business': [
      {
        value: 'Incentives',
        path: 'ebusiness/incentives',
      },
      {
        value: 'Vouchers',
        path: 'ebusiness/vouchers',
      },
      {
        value: 'Taxes',
        path: 'ebusiness/taxes',
      },
      {
        value: 'Surcharges',
        path: 'ebusiness/surcharges',
      },
      {
        value: 'Budgets',
        path: `ebusiness/budgets`,
      },
      {
        value: 'Value points',
        path: `ebusiness/value-points`,
      },
    ],
    'E-commerce': [
      {
        value: 'Channels',
        path: 'ecommerce/channels',
      },
      {
        value: 'Shops',
        path: 'ecommerce/shops',
      },
    ],
    Logistics: [
      {
        value: 'Inventory',
        path: 'logistics/inventory',
      },
      {
        value: 'Track & trace',
        path: 'logistics/track-and-trace',
      },
    ],
    Admin: [
      {
        value: 'Roles & permissions',
        path: 'admin/roles-and-permissions',
      },
      {
        value: 'Emails',
        path: 'admin/emails',
      },
      {
        value: 'SSO',
        path: 'admin/sso',
      },
      {
        value: 'Attributes',
        path: 'admin/attributes',
      },
      {
        value: 'Valueset',
        path: 'admin/valueset',
      },
    ],
  };

  constructor(
    private sidenavService: SidenavService,
    private mixpanelService: MixpanelService,
    private store: Store<fromApp.AppState>,
  ) {}

  ngOnInit(): void {
    this.sidenavService.currentTab.subscribe((tabName) => {
      this.selectedTab = tabName;
    });
    this.catalogQueryParams = this.store.select((store) => store.catalog.queryParams);
  }

  trackMenuClick(menu: string) {
    this.mixpanelService.track(menu + ' click', {
      propertyOne: 'valueOne',
      propertyTwo: 'valueTwo',
    });
  }
}
