import { Order } from 'src/app/shared/api/types/GraphQL';
import * as OrdersActions from './orders.actions';
import { TableColumns } from 'src/app/shared/api/types/enums';

export interface State {
  loading: boolean;
  loaded: boolean;
  orders: Order[];
  currentSpPage: number;
  currentBoPage: number;
  currentSpPageSize: number;
  currentBoPageSize: number;
  ordersCount: number;
  error: Error;
  columnFilterValues: {
    name: string;
    sku: string;
  };
  multiselectFilterValues: {
    manufacturer: string[];
    supplier: string[];
  };
  numberOfFiltersSelected: number;
  generalFilterValue: string;
  selectedOrdersList: Order[];
  productTableColumns: TableColumns;
  spOrdersTableColumns: TableColumns;
  boOrdersTableColumns: TableColumns;
}

const initialState: State = {
  loading: false,
  loaded: false,
  orders: [],
  currentSpPage: 1,
  currentBoPage: 1,
  currentSpPageSize: 5,
  currentBoPageSize: 5,
  ordersCount: 0,
  error: undefined,
  columnFilterValues: {
    name: '',
    sku: '',
  },
  multiselectFilterValues: {
    manufacturer: [],
    supplier: [],
  },
  numberOfFiltersSelected: 0,
  generalFilterValue: '',
  selectedOrdersList: [],
  productTableColumns: {
    displayedColumns: [
      'select',
      'image',
      'sku',
      'name',
      'supplier',
      'costPrice',
      'listPrice',
      'discount',
      'customerPrice',
      'salesDiscount',
      'salesPrice',
      'totalDiscount',
      'quantity',
      'total',
      'defaultMargin',
      'saleMargin',
      'attributedDiscount',
      'attributedMargin',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['sku', 'name'],
  },
  spOrdersTableColumns: {
    displayedColumns: ['id', 'company', 'status', 'paymentStatus', 'dateCreated'],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id', 'company'],
  },
  boOrdersTableColumns: {
    displayedColumns: ['id', 'company', 'status', 'paymentStatus', 'dateCreated'],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id', 'company'],
  },
};

export function ordersReducer(state: State = initialState, action: OrdersActions.OrdersActions) {
  switch (action.type) {
    case OrdersActions.GET_CURRENT_SP_PAGE:
      return {
        ...state,
        currentSpPage: action.payload,
      };
    case OrdersActions.GET_CURRENT_BO_PAGE:
      return {
        ...state,
        currentBoPage: action.payload,
      };
    case OrdersActions.GET_CURRENT_SP_PAGE_SIZE:
      return {
        ...state,
        currentSpPageSize: action.payload,
      };
    case OrdersActions.GET_CURRENT_BO_PAGE_SIZE:
      return {
        ...state,
        currentBoPageSize: action.payload,
      };
    case OrdersActions.GET_ORDERS_COUNT:
      return {
        ...state,
        ordersCount: action.payload,
      };
    case OrdersActions.LOAD_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case OrdersActions.REFETCH_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case OrdersActions.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        orders: [...action.payload],
        loading: false,
        loaded: true,
      };
    case OrdersActions.LOAD_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case OrdersActions.ADD_ORDER:
      let ordersCount = state.ordersCount;
      let ordersList = [...state.orders];

      //add new order
      if (state.currentSpPage === 1) {
        if (ordersList.some((e) => e.id === action.payload.id)) {
          const index = ordersList.map((x) => x.id).indexOf(action.payload.id);
          ordersList.splice(index, 1);
        } else {
          ordersList.pop();
        }
        ordersList.splice(0, 0, action.payload);
      }

      ordersCount += 1;

      return {
        ...state,
        orders: ordersList,
        ordersCount,
      };
    case OrdersActions.UPDATE_ORDER:
      const index = state.orders.map((x) => x.id).indexOf(action.payload.id);
      const updatedQuote = {
        ...state.orders[index],
        ...action.payload.updatedOrder,
      };
      const updatedQuotes = [...state.orders];
      updatedQuotes[index] = updatedQuote;
      return {
        ...state,
        orders: updatedQuotes,
      };
    case OrdersActions.DELETE_ORDER:
      if (state.orders.map((x) => x.id).indexOf(action.payload) !== -1) {
        let ordersCount = state.ordersCount;
        const index = state.orders.map((x) => x.id).indexOf(action.payload);

        ordersCount -= 1;
        return {
          ...state,
          orders: state.orders.filter((request, i) => {
            return i !== index;
          }),
          ordersCount,
        };
      } else {
        return {
          ...state,
        };
      }
    case OrdersActions.ADD_ORDER_TO_LIST:
      if (state.selectedOrdersList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedOrdersList: [...state.selectedOrdersList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case OrdersActions.DELETE_ORDER_FROM_LIST:
      if (state.selectedOrdersList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedOrdersList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedOrdersList: state.selectedOrdersList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case OrdersActions.CLEAR_ORDERS_LIST:
      return {
        ...state,
        selectedOrdersList: [],
      };
    case OrdersActions.GET_COLUMN_FILTERS:
      let numOfFilters = state.numberOfFiltersSelected;
      if (action.payload.filterValue === '' && state.columnFilterValues[action.payload.columnName] !== '') {
        numOfFilters -= 1;
      } else if (action.payload.filterValue !== '' && state.columnFilterValues[action.payload.columnName] === '') {
        numOfFilters += 1;
      }
      return {
        ...state,
        columnFilterValues: {
          ...state.columnFilterValues,
          [action.payload.columnName]: action.payload.filterValue,
        },
        numberOfFiltersSelected: numOfFilters,
      };
    case OrdersActions.GET_MULTISELECT_FILTERS:
      let stateLength =
        state.multiselectFilterValues.manufacturer.length + state.multiselectFilterValues.supplier.length;
      let payloadLength = action.payload.manufacturer.length + action.payload.supplier.length;
      let numOfMultyFilters = state.numberOfFiltersSelected;
      if (stateLength > payloadLength) {
        numOfMultyFilters = numOfMultyFilters - (stateLength - payloadLength);
      } else if (stateLength < payloadLength) {
        numOfMultyFilters = numOfMultyFilters + (payloadLength - stateLength);
      }
      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          manufacturer: action.payload.manufacturer,
          supplier: action.payload.supplier,
        },
        numberOfFiltersSelected: numOfMultyFilters,
      };
    case OrdersActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case OrdersActions.CLEAR_ALL_FILTERS:
      return {
        ...state,
        columnFilterValues: {
          name: '',
          sku: '',
        },
        multiselectFilterValues: {
          manufacturer: [],
          supplier: [],
        },
        generalFilterValue: '',
        numberOfFiltersSelected: 0,
      };
    case OrdersActions.PRODUCT_TABLE_COLUMNS:
      return {
        ...state,
        productTableColumns: action.payload,
      };
    case OrdersActions.SP_ORDERS_TABLE_COLUMNS:
      return {
        ...state,
        spOrdersTableColumns: action.payload,
      };
    case OrdersActions.BO_ORDERS_TABLE_COLUMNS:
      return {
        ...state,
        boOrdersTableColumns: action.payload,
      };
    default:
      return state;
  }
}
