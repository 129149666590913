import { Action } from '@ngrx/store';
import { BusinessRule, BusinessRuleSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[BusinessRules] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[BusinessRules] Get Current Page Size';
export const GET_BUSINESS_RULES_COUNT = '[BusinessRules] Get Business Rules Count';
export const LOAD_BUSINESS_RULES = '[BusinessRules] Load Business Rules';
export const REFETCH_BUSINESS_RULES = '[BusinessRules] Refetch Business Rules';
export const LOAD_BUSINESS_RULES_SUCCESS = '[BusinessRules] Load Business Rules Success';
export const LOAD_BUSINESS_RULES_FAILURE = '[BusinessRules] Load Business Rules Failure';

export const ADD_BUSINESS_RULE = '[BusinessRules] Add Business Rule';
export const UPDATE_BUSINESS_RULE = '[BusinessRules] Update Business Rule';
export const DELETE_BUSINESS_RULE = '[BusinessRules] Delete Business Rule';

export const GET_GENERAL_FILTER = '[BusinessRules] Get General Filter';

export const ADD_BUSINESS_RULE_TO_LIST = '[BusinessRules] Add Selected Business Rule to List';
export const DELETE_BUSINESS_RULE_FROM_LIST = '[BusinessRules] Delete Business Rule from List';
export const CLEAR_BUSINESS_RULES_LIST = '[BusinessRules] Clear Business Rules List';

export const BUSINESS_RULES_TABLE_COLUMNS = '[BusinessRules] Business Rules Table Columns';

export const BUSINESS_RULES_PAGE_INITIALIZED = '[BusinessRules] Page Initialized';
export const BUSINESS_RULES_PAGE_DESTROYED = '[BusinessRules] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetBusinessRulesCount implements Action {
  readonly type = GET_BUSINESS_RULES_COUNT;

  constructor(public payload: number) {}
}

export class LoadBusinessRules implements Action {
  readonly type = LOAD_BUSINESS_RULES;

  constructor(public payload: {}) {}
}

export class RefetchBusinessRules implements Action {
  readonly type = REFETCH_BUSINESS_RULES;

  constructor(public payload: {}) {}
}

export class LoadBusinessRulesSuccess implements Action {
  readonly type = LOAD_BUSINESS_RULES_SUCCESS;

  constructor(public payload: BusinessRule[]) {}
}

export class LoadBusinessRulesFailure implements Action {
  readonly type = LOAD_BUSINESS_RULES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddBusinessRule implements Action {
  readonly type = ADD_BUSINESS_RULE;

  constructor(public payload: BusinessRule) {}
}

// export class UpdateBusinessRule implements Action {
//     readonly type = UPDATE_BUSINESS_RULE;

//     constructor(public payload: {id: number, newBusinessRule: Order}) {}
// }
export class DeleteBusinessRule implements Action {
  readonly type = DELETE_BUSINESS_RULE;

  constructor(public payload: string) {}
}

export class AddBusinessRuleToList implements Action {
  readonly type = ADD_BUSINESS_RULE_TO_LIST;

  constructor(public payload: BusinessRule) {}
}

export class DeleteBusinessRuleFromList implements Action {
  readonly type = DELETE_BUSINESS_RULE_FROM_LIST;

  constructor(public payload: string) {}
}

export class ClearBusinessRulesList implements Action {
  readonly type = CLEAR_BUSINESS_RULES_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class BusinessRulesTableColumns implements Action {
  readonly type = BUSINESS_RULES_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class BusinessRulesPageInitialized implements Action {
  readonly type = BUSINESS_RULES_PAGE_INITIALIZED;
}

export class BusinessRulesPageDestroyed implements Action {
  readonly type = BUSINESS_RULES_PAGE_DESTROYED;
}

export type BusinessRulesActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetBusinessRulesCount
  | LoadBusinessRules
  | RefetchBusinessRules
  | LoadBusinessRulesSuccess
  | LoadBusinessRulesFailure
  | AddBusinessRule
  //| UpdateBusinessRule
  | DeleteBusinessRule
  | AddBusinessRuleToList
  | DeleteBusinessRuleFromList
  | ClearBusinessRulesList
  | BusinessRulesTableColumns
  | BusinessRulesPageInitialized
  | BusinessRulesPageDestroyed;
