import { Company } from 'src/app/shared/api/types/GraphQL';
import * as MyCompaniesActions from './my-companies.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  companies: Company[];
  currentPage: number;
  currentPageSize: number;
  companiesCount: number;
  error: Error;
  generalFilterValue: string;
  selectedCompaniesList: Company[];
}

const initialState: State = {
  loading: false,
  loaded: false,
  companies: [],
  currentPage: 1,
  currentPageSize: 5,
  companiesCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedCompaniesList: [],
};

export function myCompaniesReducer(state: State = initialState, action: MyCompaniesActions.MyCompaniesActions) {
  switch (action.type) {
    case MyCompaniesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case MyCompaniesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case MyCompaniesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case MyCompaniesActions.GET_MY_COMPANIES_COUNT:
      return {
        ...state,
        companiesCount: action.payload,
      };
    case MyCompaniesActions.LOAD_MY_COMPANIES:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case MyCompaniesActions.REFETCH_MY_COMPANIES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case MyCompaniesActions.LOAD_MY_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: [...action.payload],
        loading: false,
        loaded: true,
      };
    case MyCompaniesActions.LOAD_MY_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case MyCompaniesActions.ADD_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      };
    case MyCompaniesActions.ADD_COMPANY_TO_LIST:
      if (state.selectedCompaniesList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedCompaniesList: [...state.selectedCompaniesList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case MyCompaniesActions.DELETE_COMPANY_FROM_LIST:
      if (state.selectedCompaniesList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedCompaniesList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedCompaniesList: state.selectedCompaniesList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case MyCompaniesActions.CLEAR_MY_COMPANIES_LIST:
      return {
        ...state,
        selectedCompaniesList: [],
      };

    default:
      return state;
  }
}

// case MyCompaniesActions.UPDATE_COMPANY:
//     const index = state.companies.map(x => x.id).indexOf(action.payload.id);
//     const updatedQuote = {
//         ...state.companies[index],
//         ...action.payload.newQuote
//     }
//     const updatedQuotes = [...state.companies];
//     updatedQuotes[index] = updatedQuote;
//     return {
//         ...state,
//         companies: updatedQuotes
//     }
