import { Injectable } from '@angular/core';
import { User } from './auth-user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as fromApp from '../store/app.reducer';
import * as AuthActions from './store/auth.actions';
import { Store } from '@ngrx/store';
import * as OrdersActions from 'src/app/orders/store/orders/orders.actions';
import * as QuotesActions from 'src/app/orders/store/quotes/quotes.actions';
import * as RequestsActions from 'src/app/orders/store/requests/request.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = JSON.parse(JSON.stringify(user));
        this.store.dispatch(new AuthActions.AutoLogin(this.user));
      } else {
        this.store.dispatch(new AuthActions.LogoutStart());
      }
    });
  }

  /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  getTenants(user) {
    if (user) {
      const headerDict = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.propeller.api.key,
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.get(environment.propeller.rest.url + 'users/' + user.email, requestOptions);
    }
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            resolve(idToken);
          });
        }
      });
    });
  }

  getActiveTenant(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.select('auth').subscribe((authState) => {
        if (authState.activeTenant !== '') {
          resolve(authState.activeTenant);
        }
      });
    });
  }
}
