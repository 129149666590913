import { gql } from 'apollo-angular';

export const CreateCustomerMutation = gql`
  mutation CustomerCreate($input: CustomerInput!) {
    customerCreate(input: $input) {
      id
      customerId
    }
  }
`;

export const UpdateCustomerMutation = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    customerUpdate(input: $input) {
      firstName
      lastName
      middleName
      gender
      dateOfBirth
      primaryLanguage
    }
  }
`;

export const UpdateCustomerAddressMutation = gql`
  mutation UpdateCustomerAddress($input: CustomerAddressUpdateInput!) {
    customerAddressUpdate(input: $input) {
      id
    }
  }
`;

export const CreateCustomerAddressMutation = gql`
  mutation CreateCustomerAddress($input: CustomerAddressCreateInput!) {
    customerAddressCreate(input: $input) {
      id
    }
  }
`;

export const DeleteCustomerMutation = gql`
  mutation DeleteCustomer($id: Int!) {
    customerDelete(customerId: $id)
  }
`;
