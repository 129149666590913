import { gql } from 'apollo-angular';
import * as globals from '../../globals';

export const TenderAddressFragment = gql`
  fragment TenderAddressFields on TenderAddress {
    city
    code
    company
    country
    email
    fax
    firstName
    gender
    icp
    lastName
    middleName
    mobile
    notes
    number
    numberExtension
    phone
    postalCode
    region
    street
    url
  }
`;

export const TenderFragments = {
  address: gql`
    fragment TenderAddress on TenderAddress {
      city
      code
      company
      country
      email
      fax
      firstName
      gender
      icp
      lastName
      middleName
      mobile
      notes
      number
      numberExtension
      phone
      postalCode
      region
      street
      url
    }
  `,
  paymentData: gql`
    fragment TenderPayment on TenderPayment {
      accountingId
      amountGross
      amountNet
      method
      overruled
      status
      statusDate
      tax
      taxPercentage
      transactionId
    }
  `,
  postageData: gql`
    fragment TenderPostage on TenderPostage {
      carrier
      overruled
      partialDeliveryAllowed
      pickUpLocationId
      postageGross
      postageNet
      requestDate
      shippingMethod
      taxPercentage
      trackTrace
    }
  `,
  total: gql`
    fragment TenderTotal on TenderTotal {
      discountGross
      discountNet
      discountOverruled
      discountPercentage
      discountType
      subTotal
      subTotalNet
      taxLevels {
        price
        taxCode
      }
      totalGross
      totalNet
      totalTax
    }
  `,
  items: gql`
        fragment TenderMainItem on TenderMainItem {
            attributedSaleDiscount
            attributedSaleDiscountSum
            attributedSaleMargin
            attributedSaleMarginSum
            costPrice
            customerDiscountPercentage
            customerPrice
            discountPercentage
            expectedDeliveryDate
            externalOrderitemId
            id
            marginAmount
            marginPercentage
            name
            notes
            orderItemId
            originalPrice
            price
            priceNet
            product {
            hasBundle
            isBundleLeader
            bundles {
                id
                name
                discount
                items {
                productId
                }
            }
            cluster {
                class
                clusterId
                name (language: "${globals.appLanguage}") {
                value
                }
                sku
                options {
                isRequired
                }
            }
            mediaImages(search: { sort: ASC }) {
                items {
                alt(language: "NL") {
                    value
                }
                tags(language: "NL") {
                    values
                }
                imageVariants(
                    input: {
                    transformations: {
                        name: "medium"
                        transformation: { width: 50, height: 50, fit: BOUNDS, bgColor: "transparent", canvas: {width: 50, height: 50 }}
                    }
                    }
                ) {
                    url
                }
                }
            }
            }
            productId
            quantity
            saleMarginAmount
            saleMarginAmountSum
            saleMarginPercentage
            saleMarginPercentageSum
            sku
            sum
            sumNet
            supplier
            supplierCode
            taxCode
            taxPercentage
            totalDiscountPercentage
            totalDiscountPercentageSum
            totalPrice
            totalPriceNet
            totalSum
            totalSumNet
            valuePoints                     
        }
    `,
  childItems: gql`
    fragment TenderChildItems on ITenderBaseItem {
      attributedSaleDiscount
      attributedSaleMargin
      costPrice
      customerDiscountPercentage
      customerPrice
      discountPercentage
      expectedDeliveryDate
      externalOrderitemId
      id
      marginAmount
      marginPercentage
      name
      notes
      orderItemId
      originalPrice
      price
      priceNet
      productId
      quantity
      saleMarginAmount
      saleMarginPercentage
      sku
      supplier
      supplierCode
      taxCode
      taxPercentage
      totalDiscountPercentage
      totalPrice
      totalPriceNet
      type
      valuePoints
    }
  `,
  carriers: gql`
    fragment TenderCarrier on TenderCarrier {
      name
      description
      logo
      amount
    }
  `,
  paymethods: gql`
    fragment TenderPaymethod on TenderPaymethod {
      code
      externalCode
      type
      description
      taxCode
      amount
      allowed
    }
  `,
};
