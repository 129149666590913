import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import * as fromQuotes from '../orders/store/quotes/quotes.reducer';
import * as fromProducts from '../products/store/products.reducer';
import * as fromOrders from '../orders/store/orders/orders.reducer';
import * as fromRequests from '../orders/store/requests/requests.reducer';
import * as fromCompanies from '../companies/store/companies/companies.reducer';
import * as fromMyCompanies from '../companies/store/my-companies/my-companies.reducer';
import * as fromCatalog from '../catalog/store/catalog.reducer';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromUsers from '../crm/users/store/users/users.reducer';
import * as fromBusinessRules from '../business-rules/store/business-rules.reducer';
import * as fromFavorites from '../crm/favorites/store/favorites.reducer';
import * as fromCarriers from '../carriers/store/carriers.reducer';
import * as fromTaxes from '../e-business/taxes/store/taxes.reducer';
import * as fromWarehouses from '../warehouses/store/warehouses.reducer';

import * as AuthActions from '../auth/store/auth.actions';

export interface AppState {
  auth: fromAuth.State;
  quotes: fromQuotes.State;
  products: fromProducts.State;
  orders: fromOrders.State;
  requests: fromRequests.State;
  companies: fromCompanies.State;
  myCompanies: fromMyCompanies.State;
  catalog: fromCatalog.State;
  users: fromUsers.State;
  businessRules: fromBusinessRules.State;
  favorites: fromFavorites.State;
  carriers: fromCarriers.State;
  taxes: fromTaxes.State;
  warehouses: fromWarehouses.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  quotes: fromQuotes.quotesReducer,
  products: fromProducts.productsReducer,
  orders: fromOrders.ordersReducer,
  requests: fromRequests.requestsReducer,
  companies: fromCompanies.companiesReducer,
  myCompanies: fromMyCompanies.myCompaniesReducer,
  catalog: fromCatalog.catalogReducer,
  auth: fromAuth.authReducer,
  users: fromUsers.usersReducer,
  businessRules: fromBusinessRules.businessRulesReducer,
  carriers: fromCarriers.carriersReducer,
  taxes: fromTaxes.TaxesReducer,
  favorites: fromFavorites.favoriteListsReducer,
  warehouses: fromWarehouses.warehousesReducer,
};

export function resetAppStateOnLogout(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT) {
      let newState: Partial<AppState> = {};
      // Iterate over each slice and set it to its initialState
      Object.keys(state).forEach((slice) => {
        newState = reducer(state[slice], action);
      });
      return newState as AppState;
    }
    return reducer(state, action);
  };
}
