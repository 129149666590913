
// This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
export const environment = {
   production: false,
   debug: true,
   environment: "dev",
   mixpanel_token: "eb8d7ae62258be50965dfc0e21a3256e",
   appVersion: 20,
   boAccess: false,
   propeller: {
      api: {
         url: "https://api.helice.cloud/admin/dev/graphql",
         key: "OqVbnb5wDlb4GF1udvK2trSW5Kf8XfBzS6vD3RL3SQl5vOQc"
      },
      rest: {
         url: "https://api.helice.cloud/api/g/admin/dev/v1/"
      }
   },
   firebase: {
      apiKey: "AIzaSyCW5zkUks1iQIqxMy5twA_4ZmgzBCEmV6Q",
      authDomain: "propeller-platform-dev.firebaseapp.com",
      projectId: "propeller-platform-dev",
      storageBucket: "propeller-platform-dev.appspot.com",
      messagingSenderId: "242087505159",
      appId: "1:242087505159:web:16e7b93cc325ad5199a9a2",
      tenantId: "admin-authentication-upgo5"
   }

};
