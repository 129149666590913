import { Injectable } from '@angular/core';
import { SnackBarService } from '../components/snack-bar/snack-bar.service';

@Injectable({ providedIn: 'root' })
export class GqlErrorService {
  error: Error;

  constructor(private snackBarService: SnackBarService) {}

  getGqlError(error) {
    const errorMessage = this.extractErrorMessage(error);
    this.snackBarService.openSnackBar(errorMessage, '', 'error');
  }

  private extractErrorMessage(error): string {
    console.log(error);
    if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
      const firstError = error.graphQLErrors[0];
      const extensions = firstError.extensions;

      if (extensions) {
        if (extensions.type === 'TenderMinimumItemsViolation') {
          return 'You need to add at least one product';
        }

        if (extensions.messages && extensions.messages.length > 0) {
          return extensions.messages[0];
        }
      }
    }

    return error;
  }
}
