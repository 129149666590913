export enum AppLocation {
  sp = 'sp',
  bo = 'bo',
}

export enum MediaType {
  images,
  logos,
  icons,
  banners,
  videos,
  documents,
  manuals,
  warranties,
  paidDocuments,
}

export interface MediaTypeInterface {
  images: 'images';
  logos: 'logos';
  icons: 'icons';
  banners: 'banners';
  videos: 'videos';
  documents: 'documents';
  manuals: 'manuals';
  warranties: 'warranties';
  paidDocuments: 'paidDocuments';
}

export enum OrderType {
  dropshipment = 'order',
  quotation = 'quotation',
  request = 'request',
}

export enum OrderState {
  new = 'new',
  edit = 'edit',
  processed = 'processed',
}

export enum OrderLocation {
  quotes = 'quotes',
  orders = 'orders',
  requests = 'requests',
}

export enum BusinessRulesLocation {
  incentives = 'incentives',
  carriers = 'carriers',
}

export enum BusinessRulesLocationTypeMapping {
  incentives = 'orderIncentives',
  carriers = 'orderCarriers',
}

export enum RuleTypeToRouteMapping {
  incentives = '/ebusiness/incentives',
  carriers = '/order-management/carriers',
}

export enum AppComponentLocation {
  catalog = 'catalog',
  quotes = 'quotes',
  orders = 'orders',
  products = 'products',
  product = 'product',
  incentives = 'incentives',
  favorites = 'favorites',
}

export interface OrderStatus {
  order: {
    draft: string;
    new: string;
    confirmed: string;
    archived: string;
    unfinished: string;
    validated: string;
  };
  quotation: {
    draft: string;
    quotation: string;
  };
  request: {
    request: string;
    rejected: string;
  };
}

export interface TableColumns {
  displayedColumns: string[];
  hiddenColumns: string[];
  disabledSuffixLength: number;
  availableColumns: string[];
  disabledColumns: string[];
}

export enum OrderEditorMixpanelPrefix {
  order = 'OE',
  quote = 'QE',
  request = 'RE',
}

export enum EditableOrderStatuses {
  draft = 'DRAFT_ORDER',
  new = 'NEW',
  validated = 'VALIDATED',
}

export interface OrderStatuses {
  dropshipmentStatuses: string[];
  quoteStatuses: string[];
  requestStatuses: string[];
  rejectedStatuses: string[];
}

export enum ProductStatus {
  AVAILABLE = 'A',
  NOT_AVAILABLE = 'N',
  PHASE_OUT = 'P',
  PRESALE = 'S',
  RESTRICTED = 'R',
  OUT_OF_STOCK = 'T',
}

export const ProductStatusLableMapping = {
  [ProductStatus.AVAILABLE]: 'Available',
  [ProductStatus.NOT_AVAILABLE]: 'Not available',
  [ProductStatus.PHASE_OUT]: 'Phase out',
  [ProductStatus.PRESALE]: 'Presale',
  [ProductStatus.RESTRICTED]: 'Restricted',
  [ProductStatus.OUT_OF_STOCK]: 'Out of stock',
};

export enum isPublic {
  true = 'Is public',
  false = 'Is not public',
}

export enum isSearchable {
  true = 'Is searchable',
  false = 'Is not searchable',
}

export enum SurchargeTaxCodes {
  H = 'High VAT',
  L = 'Low VAT',
  Zero = 'No VAT',
}

export enum TaxCodes {
  H = 'High VAT',
  L = 'Low VAT',
  N = 'No VAT',
}

export enum UserTypesPlural {
  customers = 'customers',
  contacts = 'contacts',
  companies = 'companies',
}

export enum UserTypes {
  customer = 'customer',
  contact = 'contact',
  company = 'company',
}

export enum StringExpressionOperatorsTypeMapping {
  Equals = 'string',
  NotEquals = 'string',
  StartsWith = 'string',
  EndsWith = 'string',
  Contains = 'string',
  ExistsInArray = 'string',
}

export enum numberExpressionOperatorsTypeMapping {
  Equals = 'number',
  NotEquals = 'number',
  LowerThan = 'number',
  GreaterThan = 'number',
  LowerThanOrEqualTo = 'number',
  GreaterThanOrEqualTo = 'number',
}

export enum DateExpressionOperatorsTypeMapping {
  before = 'date',
  after = 'date',
  beforeDaysFromToday = 'number',
  afterDaysFromToday = 'number',
  onDaysFromToday = 'number',
  beforeMinutesFromNow = 'number',
  afterMinutesFromNow = 'number',
  onMinutesFromNow = 'number',
  onDayOfWeek = 'number',
  onDayOfMonth = 'number',
  onMonthOfYear = 'number',
  onTime = 'string',
  beforeTime = 'string',
  afterTime = 'string',
}

export enum RuleMode {
  new = 'new',
  edit = 'edit',
}

export enum ActionTypeSecondaryFields {
  Price = 'price',
  Discount = 'discount',
}

export enum IncentiveOutputFields {
  outputName = 'output.name',
  incentiveName = 'incentive.name',
  action = 'incentive.action',
  productId = 'incentive.params.productId',
  quantity = 'incentive.params.quantity',
  price = 'incentive.params.price',
  discount = 'incentive.params.discount',
  discountType = 'incentive.params.discountType',
  valuePoints = 'incentive.params.valuePoints',
  repeat = 'incentive.params.repeat',
  actionCode = 'incentive.params.actionCode',
  affectedOrderItems = 'incentive.affectedOrderItems',
  orderItemExpressionValue = 'incentive.orderItemsExpressionValue',
  orderItemsExpressionParams = 'incentive.orderItemsExpressionParam',
}

export enum IncentiveRuleActionFields {
  outputName = 'outputName',
  incentiveName = 'incentiveName',
  action = 'action',
  productId = 'productId',
  quantity = 'quantity',
  price = 'price',
  discount = 'discount',
  discountType = 'discountType',
  valuePoints = 'valuePoints',
  repeat = 'repeat',
  actionCode = 'actionCode',
  affectedOrderItems = 'affectedOrderItems',
  orderItemExpressionValue = 'orderItemExpressionValue',
  orderItemsExpressionParams = 'orderItemsExpressionParams',
}

export enum CarrierOutputFields {
  carrierRuleName = 'carrier.ruleName',
  action = 'carrier.action',
  carrierId = 'carrier.params.carrierId',
}

export enum CarrierRuleActionFields {
  carrierRuleName = 'carrierRuleName',
  action = 'action',
  carrierId = 'carrierId',
}

export enum BusinessRuleActionCodeExpressionOperators {
  Equals = 'equals',
}

export enum IncentiveActions {
  AddProductToBonusItems = 'ADD_PRODUCT_TO_BONUS_ITEMS',
  //DuplicateProductInBonusItems = "DUPLICATE_PRODUCT_IN_BONUS_ITEMS",
  //MoveProductToBonusItems = "MOVE_PRODUCT_TO_BONUS_ITEMS",
  //DuplicateCheapestInBonusItems = "DUPLICATE_CHEAPEST_IN_BONUS_ITEMS",
  GiveDiscountToCheapestItem = 'GIVE_DISCOUNT_TO_CHEAPEST_ITEM',
  GiveDiscountToOrderItem = 'GIVE_DISCOUNT_TO_ORDER_ITEM',
  GiveDiscountToOrderTotal = 'GIVE_DISCOUNT_TO_ORDER_TOTAL',
  AddValuepointsToOrder = 'ADD_VALUEPOINTS_TO_ORDER',
  SetShippingCosts = 'SET_SHIPPING_COSTS',
  SetTransactionCosts = 'SET_TRANSACION_COSTS',
}

export enum CarrierActions {
  SetCarrierToSelectable = 'SET_CARRIER_TO_SELECTABLE',
}

export enum ListingTypes {
  taxes = 'taxes',
  favorites = 'favorites',
  users = 'users',
}

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum DeliveryMethods {
  REGULAR = 'Delivery',
  PICKUP = 'Pick up',
}
