import { gql } from 'apollo-angular';
import * as globals from '../../globals';

export const UsergroupsListQuery = gql`
  query usergroups($input: UsergroupSearchArguments!) {
    usergroups(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        usergroupId
        name
        slug
        companies {
          itemsFound
        }
      }
    }
  }
`;
