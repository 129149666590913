import { gql } from 'apollo-angular';

export const SurchargesQuery = gql`
  query SurchargesQuery($input: SurchargeSearchInput) {
    surcharges(input: $input) {
      itemsFound
      page
      items {
        id
        name {
          language
          value
        }
        description {
          language
          value
        }
        type
        value
        taxCode
        taxZone
        enabled
        validFrom
        validTo
        createdBy
        changedBy
        dateCreated
        dateChanged
        shopId
      }
    }
  }
`;
