import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';
import * as FavoritesActions from './favorites.actions';
import { FavoritesService } from '../favorites.service';
import { FavoriteListsResponse } from '../../../shared/api/types/GraphQL';

@Injectable()
export class FavoritesEffects {
  query: QueryRef<any>;
  querySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private favoritesService: FavoritesService,
  ) {}

  public readonly loadUsers: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(FavoritesActions.LOAD_FAVORITE_LISTS),
      mergeMap((action: FavoritesActions.LoadFavoriteLists) => {
        this.query = this.favoritesService.getFavoriteLists(action.payload);
        this.querySub = this.query.valueChanges.pipe(
          map((data) => {
            return new FavoritesActions.LoadFavoriteListsSuccess({
              items: data.data.favoriteLists.items,
              itemsFound: data.data.favoriteLists.itemsFound,
            });
          }),
          catchError((error) => of(new FavoritesActions.LoadFavoriteListsFailure(error))),
        );
        return this.querySub;
      }),
    );
  });

  public readonly refetchUsers = createEffect(
    () =>
      this.actions.pipe(
        ofType(FavoritesActions.REFETCH_FAVORITE_LISTS),
        tap((action: FavoritesActions.RefetchFavoriteLists) => {
          this.query.resetLastResults();
          this.query.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
