import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { OrderSetStatus } from '../shared/api/mutations/order-mutations';
import {
  TenderAddItems,
  TenderApplyIncentives,
  TenderDelete,
  TenderDeleteItem,
  TenderProcess,
  TenderStartMutation,
  TenderUpdate,
  TenderUpdateAddress,
  TenderUpdateDiscount,
  TenderUpdateItem,
  TenderUpdatePayment,
  TenderUpdatePostage,
} from '../shared/api/mutations/tender-mutations';
import {
  AllQuotesQuery,
  OrderGetPDF,
  OrderSendConfirmationEmail,
  QuoteQuery,
  SingleListQuoteQuery,
  orderStatusQuery,
} from '../shared/api/queries/quote-queries';
import { TenderQuery } from '../shared/api/queries/tender-queries';
import { WarehousesQuery } from '../shared/api/queries/warehouse-queries';
import {
  Base64FileInput,
  OrderSetStatusInput,
  TenderAddItemsInput,
  TenderDeleteItemInput,
  TenderDiscountInput,
  TenderPaymentInput,
  TenderPostageInput,
  TenderStart,
  TenderUpdateAddressInput,
  TenderUpdateInput,
  TenderUpdateItemInput,
  WarehousesSearchInput,
} from '../shared/api/types/GraphQL';
import {
  OrderGetPdfResponse,
  OrderListResponse,
  OrderResponseType,
  OrderSearchInput,
  OrderSendConfirmationEmailResponse,
  OrderSetStatusResponse,
  QueryTenderResponse,
  TenderAddItemsResponse,
  TenderDeleteItemResponse,
  TenderDeleteResponse,
  TenderIncentivesResponse,
  TenderQueryInput,
  TenderSaveResponse,
  TenderStartResponese,
  TenderUpdateAddressResponse,
  TenderUpdateDiscountResponse,
  TenderUpdateItemResponse,
  TenderUpdatePaymentResponse,
  TenderUpdatePostageResponse,
  TenderUpdateResponse,
  WarehouseListResponse,
} from '../shared/api/types/types';
import { OrderStatuses } from '../shared/api/types/enums';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  private readonly orderStatuses: Readonly<OrderStatuses>;

  constructor(private apollo: Apollo) {
    this.orderStatuses = Object.freeze({
      dropshipmentStatuses: ['DRAFT_ORDER', 'NEW', 'CONFIRMED', 'ARCHIVED', 'UNFINISHED', 'VALIDATED'],
      quoteStatuses: ['DRAFT_QUOTATION', 'QUOTATION'],
      requestStatuses: ['REQUEST'],
      rejectedStatuses: ['REJECTED'],
    });
  }

  getOrderStatuses() {
    return this.orderStatuses;
  }

  getAllQuotes(variables: any): QueryRef<OrderListResponse> {
    return this.apollo.watchQuery<OrderListResponse>({
      query: AllQuotesQuery,
      variables,
      //notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });
  }

  getOrderStatus(variables: OrderSearchInput): QueryRef<OrderResponseType> {
    return this.apollo.watchQuery<OrderResponseType>({
      query: orderStatusQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getQuote(variables: OrderSearchInput): QueryRef<OrderResponseType> {
    return this.apollo.watchQuery<OrderResponseType>({
      query: QuoteQuery,
      variables,
    });
  }

  getSingleListQuote(variables: OrderSearchInput): QueryRef<OrderResponseType> {
    return this.apollo.watchQuery<OrderResponseType>({
      query: SingleListQuoteQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getTender(variables: TenderQueryInput): QueryRef<QueryTenderResponse> {
    return this.apollo.watchQuery<QueryTenderResponse>({
      query: TenderQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getWarehouseList(variables: WarehousesSearchInput): QueryRef<WarehouseListResponse> {
    return this.apollo.watchQuery<WarehouseListResponse>({
      query: WarehousesQuery,
      variables: { input: variables },
    });
  }

  orderSendConfirmationEmail(orderId: number, attachments?: Base64FileInput[]) {
    return this.apollo.watchQuery<OrderSendConfirmationEmailResponse>({
      query: OrderSendConfirmationEmail,
      variables: { orderId, attachments },
    });
  }

  orderGetPdf(orderId: number) {
    return this.apollo.watchQuery<OrderGetPdfResponse>({
      query: OrderGetPDF,
      variables: { orderId: orderId },
    });
  }

  initializeTender(variables: TenderStart) {
    return this.apollo.mutate<TenderStartResponese>({
      mutation: TenderStartMutation,
      variables: { input: variables },
    });
  }

  tenderUpdateAddress(variables: TenderUpdateAddressInput) {
    return this.apollo.mutate<TenderUpdateAddressResponse>({
      mutation: TenderUpdateAddress,
      variables: { input: variables },
    });
  }

  tenderAddItems(variables: TenderAddItemsInput) {
    return this.apollo.mutate<TenderAddItemsResponse>({
      mutation: TenderAddItems,
      variables: { input: variables },
    });
  }

  tenderDeleteItem(variables: TenderDeleteItemInput) {
    return this.apollo.mutate<TenderDeleteItemResponse>({
      mutation: TenderDeleteItem,
      variables: { input: variables },
    });
  }

  tenderUpdateItem(variables: TenderUpdateItemInput) {
    return this.apollo.mutate<TenderUpdateItemResponse>({
      mutation: TenderUpdateItem,
      variables: { input: variables },
    });
  }

  tenderUpdateDiscount(variables: TenderDiscountInput) {
    return this.apollo.mutate<TenderUpdateDiscountResponse>({
      mutation: TenderUpdateDiscount,
      variables: { input: variables },
    });
  }

  tenderUpdatePayment(variables: TenderPaymentInput) {
    return this.apollo.mutate<TenderUpdatePaymentResponse>({
      mutation: TenderUpdatePayment,
      variables: { input: variables },
    });
  }

  tenderUpdatePostage(variables: TenderPostageInput) {
    return this.apollo.mutate<TenderUpdatePostageResponse>({
      mutation: TenderUpdatePostage,
      variables: { input: variables },
    });
  }

  tenderUpdate(variables: TenderUpdateInput) {
    return this.apollo.mutate<TenderUpdateResponse>({
      mutation: TenderUpdate,
      variables: { input: variables },
    });
  }

  tenderProcess(tenderId: string) {
    return this.apollo.mutate<TenderSaveResponse>({
      mutation: TenderProcess,
      variables: { tenderId: tenderId },
    });
  }

  tenderApplyIncentives(tenderId: string) {
    return this.apollo.mutate<TenderIncentivesResponse>({
      mutation: TenderApplyIncentives,
      variables: { tenderId: tenderId },
    });
  }

  tenderDelete(tenderId: string) {
    return this.apollo.mutate<TenderDeleteResponse>({
      mutation: TenderDelete,
      variables: { tenderId: tenderId },
    });
  }

  orderSetStatus(variables: OrderSetStatusInput) {
    return this.apollo.mutate<OrderSetStatusResponse>({
      mutation: OrderSetStatus,
      variables: { input: variables },
    });
  }
}
