import { Action } from '@ngrx/store';
import { Contact, Company, Customer } from '../../../../shared/api/types/GraphQL';
import { UserTypesPlural } from '../../../../shared/api/types/enums';

export const GET_CURRENT_PAGE = '[Users] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Users] Get Current Page Size';
export const SET_LOADING_TRUE = '[Users] Set Loading True';
export const REFETCH_USERS = '[Users] Refetch users';
export const LOAD_USERS = '[Users] Load Users';
export const LOAD_USERS_SUCCESS = '[Users] Load Users success';
export const LOAD_USERS_FAILURE = '[Users] Load Users failure';
export const ADD_USER_TO_USER_LIST = '[Users] Add User to Selected User List';
export const DELETE_USER_FROM_USER_LIST = '[Users] Delete User from Selected User List';
export const TAB_CHANGE = '[Users] Tab change';
export const CLEAR_SELECTED_USERS_LIST = '[Users] Clear selected users list';
export const USERS_TABLE_COLUMNS = '[Users] Users Table Columns';

export type User = Contact | Customer | Company;

export type UserList = Contact[] | Company[] | Customer[];

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;
  constructor(public payload: number) {}
}
export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class SetLoadingTrue implements Action {
  readonly type = SET_LOADING_TRUE;
}

export class RefetchUsers implements Action {
  readonly type = REFETCH_USERS;
  constructor(public payload: {}) {}
}

export class LoadUsers implements Action {
  readonly type = LOAD_USERS;
  constructor(
    public payload: {},
    public tab: UserTypesPlural,
  ) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = LOAD_USERS_SUCCESS;

  constructor(
    public payload: {
      data: Contact[] | Customer[] | Company[];
      itemsFound: number;
      tab: UserTypesPlural;
    },
  ) {}
}

export class LoadUsersFailure implements Action {
  readonly type = LOAD_USERS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddUserToUserList implements Action {
  readonly type = ADD_USER_TO_USER_LIST;
  constructor(public payload: User) {}
}

export class DeleteUserFromUserList implements Action {
  readonly type = DELETE_USER_FROM_USER_LIST;
  constructor(public payload: number) {}
}

export class ClearSelectedUsersList implements Action {
  readonly type = CLEAR_SELECTED_USERS_LIST;
}
export class TabChange implements Action {
  readonly type = TAB_CHANGE;
  constructor(public payload: UserTypesPlural) {}
}

export class UsersTableColumns implements Action {
  readonly type = USERS_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export type UsersActions =
  | GetCurrentPageSize
  | GetCurrentPage
  | SetLoadingTrue
  | RefetchUsers
  | LoadUsersSuccess
  | LoadUsers
  | LoadUsersFailure
  | AddUserToUserList
  | DeleteUserFromUserList
  | UsersTableColumns
  | TabChange
  | ClearSelectedUsersList;
