import { AttributeFilter, Product } from 'src/app/shared/api/types/GraphQL';
import * as CatalogActions from './catalog.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  componentReload: boolean;
  products: Product[];
  currentPage: number;
  currentPageSize: number;
  productsCount: number;
  error: Error;
  columnFilterValues: {
    name: string;
    sku: string;
  };
  multiselectFilterValues: {
    manufacturer: string[];
    supplier: string[];
  };
  generalFilterValue: string;
  attributeFilters: AttributeFilter[];
  productListProducts: Product[];
  productListQuotes: Product[];
  productListOrders: Product[];
  queryParams: {};
}

const initialState: State = {
  loading: false,
  loaded: false,
  componentReload: false,
  products: [],
  currentPage: 1,
  currentPageSize: 6,
  productsCount: 0,
  error: undefined,
  columnFilterValues: {
    name: '',
    sku: '',
  },
  multiselectFilterValues: {
    manufacturer: [],
    supplier: [],
  },
  generalFilterValue: '',
  attributeFilters: [],
  productListProducts: [],
  productListQuotes: [],
  productListOrders: [],
  queryParams: {},
};

export function catalogReducer(state: State = initialState, action: CatalogActions.CatalogActions) {
  switch (action.type) {
    case CatalogActions.GET_COLUMN_FILTERS:
      if (action.payload.columnName === 'name') {
        return {
          ...state,
          columnFilterValues: {
            ...state.columnFilterValues,
            name: action.payload.filterValue,
          },
        };
      }
      return {
        ...state,
        columnFilterValues: {
          ...state.columnFilterValues,
          sku: action.payload.filterValue,
        },
      };
    case CatalogActions.GET_MULTISELECT_FILTERS:
      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          manufacturer: action.payload.manufacturer,
          supplier: action.payload.supplier,
        },
      };
    case CatalogActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case CatalogActions.GET_ATTRIBUTE_FILTRS:
      return {
        ...state,
        attributeFilters: action.payload,
      };
    case CatalogActions.CLEAR_ALL_FILTERS:
      return {
        ...state,
        columnFilterValues: {
          name: '',
          sku: '',
        },
        multiselectFilterValues: {
          manufacturer: [],
          supplier: [],
        },
        generalFilterValue: '',
      };
    case CatalogActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case CatalogActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case CatalogActions.GET_CATALOG_COUNT:
      return {
        ...state,
        productsCount: action.payload,
      };
    case CatalogActions.LOAD_CATALOG:
      return {
        ...state,
        componentReload: false,
        loaded: false,
        loading: true,
      };
    case CatalogActions.REFETCH_CATALOG:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case CatalogActions.LOAD_CATALOG_SUCCESS:
      return {
        ...state,
        products: [...action.payload],
        loading: false,
        componentReload: true,
        loaded: true,
      };
    case CatalogActions.LOAD_CATALOG_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: true,
      };
    case CatalogActions.ADD_PRODUCT_TO_LIST_CATALOG:
      if (state.productListProducts.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListProducts: [...state.productListProducts, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CatalogActions.DELETE_PRODUCT_FROM_LIST_CATALOG:
      if (state.productListProducts.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListProducts.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListProducts: state.productListProducts.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case CatalogActions.CLEAR_PRODUCT_LIST_CATALOG:
      return {
        ...state,
        productListProducts: [],
      };
    case CatalogActions.ADD_PRODUCT_TO_LIST_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListQuotes: [...state.productListQuotes, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CatalogActions.DELETE_PRODUCT_FROM_LIST_QUOTES:
      if (state.productListQuotes.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListQuotes.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListQuotes: state.productListQuotes.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case CatalogActions.CLEAR_PRODUCT_LIST_QUOTES:
      return {
        ...state,
        productListQuotes: [],
      };
    case CatalogActions.ADD_PRODUCT_TO_LIST_ORDERS:
      if (state.productListOrders.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          productListOrders: [...state.productListOrders, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CatalogActions.DELETE_PRODUCT_FROM_LIST_ORDERS:
      if (state.productListOrders.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.productListOrders.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          productListOrders: state.productListOrders.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case CatalogActions.CLEAR_PRODUCT_LIST_ORDERS:
      return {
        ...state,
        productListOrders: [],
      };
    case CatalogActions.SAVE_QUERY_PARAMS:
      return {
        ...state,
        queryParams: action.payload,
      };
    case CatalogActions.CATALOG_PAGE_DESTROYED:
      return {
        ...state,
        componentReload: false,
      };
    default:
      return state;
  }
}
