import { gql } from 'apollo-angular';

import * as globals from '../../globals';

export const AllProductsQuery = gql`
query products ($path: String, $page: Int, $offset: Int, $sort: [SortInput!], $term: String, $searchFields: [SearchFieldsInput!], $language: String, $manufacturer: [String!], $supplier: [String!], $sku: [String!], $class: ProductClass, $userId: Int) {
  products(path: $path, page: $page, offset: $offset, sort: $sort, term: $term, searchFields: $searchFields, language: $language, manufacturer: $manufacturer, supplier: $supplier, sku: $sku, class: $class) {
    page
    itemsFound
    pages
    offset
    start
    items {
      ... on Cluster {
        name (language: "${globals.appLanguage}") {
          value
        }
        class
        sku
        id
        products {
          name (language: "${globals.appLanguage}") {
            value
          }
          id
          productId
          manufacturer
          supplier
          inventory {
            totalQuantity
          }
          unit
          purchaseMinimumQuantity
          price (userId: $userId){
            gross
            net
          }
          sku
          isOrderable
          status
          media {
            images(search: { sort: ASC, offset: 1 }) {
              items {
                alt(language: "${globals.appLanguage}") {
                  value
                }
                imageVariants(
                  input: {
                    transformations: {
                      name: "small"
                      transformation: { width: 50, height: 50, fit: BOUNDS, bgColor: "transparent", canvas: {width: 50, height: 50}}
                    }
                  }
                ) {
                  url
                }
              }
            }
          }
        }
        defaultProduct {
          name (language: "${globals.appLanguage}") {
            value
          }
          id
          productId
          manufacturer
          supplier
          inventory {
            totalQuantity
          }
          unit
          purchaseMinimumQuantity
          price (userId: $userId){
            gross
            net
          }
          sku
          isOrderable
          status
          media {
            images(search: { sort: ASC, offset: 1 }) {
              items {
                alt(language: "${globals.appLanguage}") {
                  value
                }
                imageVariants(
                  input: {
                    transformations: {
                      name: "small"
                      transformation: { width: 50, height: 50, fit: BOUNDS, bgColor: "transparent", canvas: {width: 50, height: 50} }
                    }
                  }
                ) {
                  url
                }
              }
            }
          }
        }
      }
      ... on Product {
        sku
        name (language: "${globals.appLanguage}") {
          value
        }
        id
        productId
        class
        cluster {
          id
        }
        minimumQuantity
        purchaseMinimumQuantity
        unit
        manufacturer
        supplier     
        price (userId: $userId){
          gross
          net
        }
        isOrderable
        status
        inventory {
          totalQuantity
        }
        media {
          images(search: { sort: ASC, offset: 1 }) {
            items {
              alt(language: "${globals.appLanguage}") {
                value
              }
              imageVariants(
                input: {
                  transformations: {
                    name: "small"
                    transformation: { width: 50, height: 50, fit: BOUNDS, bgColor: "transparent", canvas: {width: 50, height: 50} }
                  }
                }
              ) {
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

export const ProductsByProductCode = gql`
  query products(
    $path: String
    $page: Int
    $offset: Int
    $sort: [SortInput!]
    $term: String
    $searchFields: [SearchFieldsInput!]
    $language: String
    $manufacturer: [String!]
    $supplier: [String!]
    $sku: [String!]
    $class: ProductClass
    $userId: Int
  ) {
    products(
      path: $path
      page: $page
      offset: $offset
      sort: $sort
      term: $term
      searchFields: $searchFields
      language: $language
      manufacturer: $manufacturer
      supplier: $supplier
      sku: $sku
      class: $class
      userId: $userId
    ) {
      itemsFound
      items {
        ... on Product {
          sku
          productId
          minimumQuantity
        }
      }
    }
  }
`;
export const CatalogQuery = gql`
query products ($path: String, $page: Int, $offset: Int, $sort: [SortInput!], $term: String, $searchFields: [SearchFieldsInput!], $language: String, $manufacturer: [String!], $supplier: [String!], $sku: [String!], $textFilters: [TextFilterInput!], $rangeFilters: [RangeFilterInput!], $class: ProductClass, $userId: Int) {
  products(path: $path, page: $page, offset: $offset, sort: $sort, term: $term, searchFields: $searchFields, language: $language, manufacturer: $manufacturer, supplier: $supplier, sku: $sku, textFilters: $textFilters, rangeFilters: $rangeFilters, class: $class) {
    page
    itemsFound
    pages
    offset
    filters (filter: {isPublic:true, isSearchable:true}) {
      description
      searchId
      decimalRangeFilter {
        max
        min
      }
      textFilter {
        count
        value
        countTotal
        countActive
        isSelected
      }
      type      
    }
    items {
      ... on Cluster {
        name {
          value
        }
        class
        sku
        products {
          name {
            value
          }
          manufacturerCode
          supplierCode
          inventory {
            totalQuantity
          }
          unit
          purchaseMinimumQuantity
          price (userId: $userId){
            gross
            net
          }
          sku
          productId
        }
        defaultProduct {
          name {
            value
          }
          manufacturerCode
          supplierCode
          inventory {
            totalQuantity
          }
          unit
          purchaseMinimumQuantity
          price (userId: $userId){
            gross
            net
          }
          sku
          productId
          media {
            images(search: { sort: ASC, offset: 1 }) {
              items {
                alt(language: "${globals.appLanguage}") {
                  value
                }
                imageVariants(
                  input: {
                    transformations: {
                      name: "small"
                      transformation: { width: 240, height: 240, fit: BOUNDS, bgColor: "transparent", canvas: {width: 240, height: 240} }
                    }
                  }
                ) {
                  url
                }
              }
            }
          }
        }
      }
      ... on Product {
        class
        sku
        name (language: "${globals.appLanguage}") {
          value
        }
        productId
        inventory {
          totalQuantity
        }
        manufacturerCode
        supplierCode
        price (userId: $userId){
          gross
          net
        }
        purchaseMinimumQuantity
        unit
        media {
          images(search: { sort: ASC, offset: 1 }) {
            items {
              alt(language: "${globals.appLanguage}") {
                value
              }
              imageVariants(
                input: {
                  transformations: {
                    name: "small"
                    transformation: { width: 240, height: 240, fit: BOUNDS, bgColor: "transparent", canvas: {width: 240, height: 240} }
                  }
                }
              ) {
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

export const CatalogProductDetailsQuery = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String, $userId: Int) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {   
    sku
    name (language: "${globals.appLanguage}") {
      value
    }
    id
    productId
    inventory {
      totalQuantity
    }
    manufacturerCode
    manufacturer
    supplier
    supplierCode
    price (userId: $userId){
      gross
      net
    }
    bulkPrices (taxZone: "${globals.appLanguage}") {
      gross
      net 
      from
      to
    }
    costPrice
    purchaseMinimumQuantity
    unit
    package
    description (language: "${globals.appLanguage}") {
      value
    }
    shortDescription (language: "${globals.appLanguage}") {
      value
    }
    media {
      images(search: { sort: ASC }) {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          imageVariants(
            input: {
              transformations: [{
                name: "small"
                transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
              },
              {
                name: "medium"
                transformation: { width: 410, height: 410, fit: BOUNDS, bgColor: "transparent", canvas: {width: 410, height: 410} }
              }]
            }
          ) {
            url
          }
        }
      }
    }
  }
}
`;

export const ClusterTypeQuery = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {       
    id
    productId
    cluster {      
      options {
        name {
          value
        }
        defaultProduct {
          name (language: "${globals.appLanguage}") {
            value
          }
        } 
      }
      drillDown {
        attribute {
          name 
        }
        priority
      }
    } 
  }
}
`;

export const NormalClasterQuery = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String, $userId: Int) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {   
     cluster {
      name {
        value
      }
      class
      clusterId
      sku
      id
      products {
        sku
        name (language: "${globals.appLanguage}") {
          value
        }
        id
        productId
        cluster {
          name {
            value
          }
          id
        }
        class
        inventory {
          totalQuantity
        }
        manufacturerCode
        manufacturer
        supplier
        supplierCode
        minimumQuantity
        price (userId: $userId){
          gross
          net
        }
        bulkPrices (taxZone: "${globals.appLanguage}") {
          gross
          net 
          from
          to
        }
        costPrice
        purchaseMinimumQuantity
        unit
        package
        description (language: "${globals.appLanguage}") {
          value
        }
        shortDescription (language: "${globals.appLanguage}") {
          value
        }
        media {
          images(search: { sort: ASC }) {
            items {
              alt(language: "${globals.appLanguage}") {
                value
              }
              imageVariants(
                input: {
                  transformations: [{
                    name: "small"
                    transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                  },
                  {
                    name: "medium"
                    transformation: { width: 410, height: 410, fit: BOUNDS, bgColor: "transparent", canvas: {width: 410, height: 410} }
                  }]
                }
              ) {
                url
              }
            }
          }
        }
      }
      options {
        name {
          value
        }
        isRequired
        isHidden
        defaultProduct {
          name (language: "${globals.appLanguage}") {
            value
          }
        } 
      }
     } 
  }
}
`;

export const LinearClusterQuery = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String, $userId: Int, $attributeNames:[String!]) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {       
     cluster {
      name {
        value
      }
      class
      sku
      id
      clusterId
      products {
        sku
        name (language: "${globals.appLanguage}") {
          value
        }
        id
        productId
        cluster {
          name {
            value
          }
          id
        }
        class
        inventory {
          totalQuantity
        }
        manufacturerCode
        manufacturer
        supplier
        supplierCode
        minimumQuantity
        price (userId: $userId){
          gross
          net
        }
        bulkPrices (taxZone: "${globals.appLanguage}") {
          gross
          net 
          from
          to
        }
        costPrice
        purchaseMinimumQuantity
        unit
        package
        description (language: "${globals.appLanguage}") {
          value
        }
        shortDescription (language: "${globals.appLanguage}") {
          value
        }
        media {
          images(search: { sort: ASC }) {
            items {
              alt(language: "${globals.appLanguage}") {
                value
              }
              imageVariants(
                input: {
                  transformations: [{
                    name: "small"
                    transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                  },
                  {
                    name: "medium"
                    transformation: { width: 410, height: 410, fit: BOUNDS, bgColor: "transparent", canvas: {width: 410, height: 410} }
                  }]
                }
              ) {
                url
              }
            }
          }
        }
        attributeValues (filter: {name: $attributeNames}) {
          itemsFound
          page
          items{
            dateValue
            decimalValue     
            enumValue
            intValue
            textValue (language: "${globals.appLanguage}") {
              values
            }
            attributeDescription {
              name
              description (language: "${globals.appLanguage}") {
                value
              }
              type
            }
          }
        }
      }
      drillDown {
        attributeId
        attribute {
          name 
          description (language: "${globals.appLanguage}") {
            value
          }
          type
          typeParam
        }
        priority
        displayType
      }
     } 
  }
}
`;

export const ConfigurableClusterQuery = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String, $userId: Int) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {       
     cluster {
      name {
        value
      }
      class
      sku
      id
      clusterId
      products {
        sku
        name (language: "${globals.appLanguage}") {
          value
        }
        id
        productId
        cluster {
          name {
            value
          }
          id
          products {
            id
          }
          options {
            products {
              id
            }
          }
        }
        class
        inventory {
          totalQuantity
        }
        manufacturerCode
        manufacturer
        supplier
        supplierCode
        minimumQuantity
        price (userId: $userId){
          gross
          net
        }
        bulkPrices (taxZone: "${globals.appLanguage}") {
          gross
          net 
          from
          to
        }
        costPrice
        purchaseMinimumQuantity
        unit
        package
        description (language: "${globals.appLanguage}") {
          value
        }
        shortDescription (language: "${globals.appLanguage}") {
          value
        }
        media {
          images(search: { sort: ASC }) {
            items {
              alt(language: "${globals.appLanguage}") {
                value
              }
              imageVariants(
                input: {
                  transformations: [{
                    name: "small"
                    transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                  },
                  {
                    name: "medium"
                    transformation: { width: 410, height: 410, fit: BOUNDS, bgColor: "transparent", canvas: {width: 410, height: 410} }
                  }]
                }
              ) {
                url
              }
            }
          }
        }
        
      }
      options {
        name {
          value
        }
        isRequired
        defaultProduct {
          productId
          price {
            net
          }
        } 
        products {
          sku
          name (language: "${globals.appLanguage}") {
            value
          }
          id
          productId
          cluster {
            name {
              value
            }
            id
          }
          class
          inventory {
            totalQuantity
          }
          manufacturerCode
          manufacturer
          supplier
          supplierCode
          minimumQuantity
          price (userId: $userId){
            gross
            net
          }
          bulkPrices (taxZone: "${globals.appLanguage}") {
            gross
            net 
            from
            to
          }
          costPrice
          purchaseMinimumQuantity
          unit
          package
          description (language: "${globals.appLanguage}") {
            value
          }
          shortDescription (language: "${globals.appLanguage}") {
            value
          }
          media {
            images(search: { sort: ASC }) {
              items {
                alt(language: "${globals.appLanguage}") {
                  value
                }
                imageVariants(
                  input: {
                    transformations: [{
                      name: "small"
                      transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                    },
                    {
                      name: "medium"
                      transformation: { width: 410, height: 410, fit: BOUNDS, bgColor: "transparent", canvas: {width: 410, height: 410} }
                    }]
                  }
                ) {
                  url
                }
              }
            }
          }
        }
      }
     } 
  }
}
`;

export const ProductSpecifications = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {       
    attributeValues (filter: {isPublic: true, offset: 1000}) {
      itemsFound
      page
      items{
        dateValue
        decimalValue     
        enumValue
        intValue
        textValue (language: "${globals.appLanguage}") {
          values
        }
        attributeDescription {
          name
          description (language: "${globals.appLanguage}") {
            value
          }
          type
        }
      }
    }
  }
}
`;

export const ProductDocuments = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {       
    media {
      documents {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          tags(language: "${globals.appLanguage}") {
            values
          }
          description(language: "${globals.appLanguage}") {
            value
          }
          type
          documents {
            language
            originalUrl
            mimeType
          }
        }
      }
    }
  }
}
`;

export const ProductVideos = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {       
    media {
      videos {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          description(language: "${globals.appLanguage}") {
            value
          }
          tags(language: "${globals.appLanguage}") {
            values
          }
          type
          videos {
            language
            uri
            mimeType

          }
        }
      }
    }
  }
}
`;
