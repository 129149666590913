import { Action } from '@ngrx/store';
import { FavoriteList, FavoriteListsResponse } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Favorites] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Favorites] Get Current Page Size';
export const GET_FAVORITE_LISTS_COUNT = '[Favorites] Get Favorites Count';
export const LOAD_FAVORITE_LISTS = '[Favorites] Load Favorite Lists';
export const REFETCH_FAVORITE_LISTS = '[Favorites] Refetch Favorite Lists';
export const LOAD_FAVORITE_LISTS_SUCCESS = '[Favorites] Load Favorites Success';
export const LOAD_FAVORITE_LISTS_FAILURE = '[Favorites] Load Favorites Failure';

export const GET_GENERAL_FILTER = '[Favorites] Get General Filter';

export const ADD_FAVORITE_LIST_TO_SELECTED_LISTS = '[Favorites] Add Favorite List To Selected List';
export const DELETE_FAVORITE_LIST_FROM_SELECTED_LIST = '[Favorites] Delete Favorite List From Selected List';
export const CLEAR_SELECTED_FAVORITES_LISTS = '[Favorites] Clear Selected Favorite List';

export const FAVORITES_PAGE_INITIALIZED = '[Favorites] Page Initialized';
export const FAVORITES_PAGE_DESTROYED = '[Favorites] Page Destroyed';

export const FAVORITES_TABLE_COLUMNS = '[Favorites] Favorites Table Columns';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) { }
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) { }
}

export class GetFavoriteListsCount implements Action {
  readonly type = GET_FAVORITE_LISTS_COUNT;

  constructor(public payload: number) { }
}

export class LoadFavoriteLists implements Action {
  readonly type = LOAD_FAVORITE_LISTS;

  constructor(public payload: {}) { }
}

export class RefetchFavoriteLists implements Action {
  readonly type = REFETCH_FAVORITE_LISTS;

  constructor(public payload: {}) { }
}

export class LoadFavoriteListsSuccess implements Action {
  readonly type = LOAD_FAVORITE_LISTS_SUCCESS;

  constructor(
    public payload: {
      items: FavoriteList[];
      itemsFound: number;
    },
  ) { }
}

export class LoadFavoriteListsFailure implements Action {
  readonly type = LOAD_FAVORITE_LISTS_FAILURE;

  constructor(public payload: Error) { }
}

export class AddFavoriteListToSelectedLists implements Action {
  readonly type = ADD_FAVORITE_LIST_TO_SELECTED_LISTS;

  constructor(public payload: FavoriteList) { }
}

export class DeleteFavoriteListFromSelectedLists implements Action {
  readonly type = DELETE_FAVORITE_LIST_FROM_SELECTED_LIST;

  constructor(public payload: string) { }
}

export class ClearFavoriteListsList implements Action {
  readonly type = CLEAR_SELECTED_FAVORITES_LISTS;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) { }
}

export class FavoriteListsPageInitialized implements Action {
  readonly type = FAVORITES_PAGE_INITIALIZED;
}

export class FavoriteListsPageDestroyed implements Action {
  readonly type = FAVORITES_PAGE_DESTROYED;
}

export class FavoritesTableColumns implements Action {

  readonly type = FAVORITES_TABLE_COLUMNS;
  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) { }
}

export type FavoriteListsActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetFavoriteListsCount
  | LoadFavoriteLists
  | RefetchFavoriteLists
  | LoadFavoriteListsSuccess
  | LoadFavoriteListsFailure
  | AddFavoriteListToSelectedLists
  | DeleteFavoriteListFromSelectedLists
  | ClearFavoriteListsList
  | FavoriteListsPageInitialized
  | FavoriteListsPageDestroyed
  | FavoritesTableColumns
